import { Injectable, ErrorHandler } from '@angular/core';
import { ToastService } from '../toast/toast.service';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  constructor(private readonly toast: ToastService) {
    super();
  }

  public handleError(error: any) {
    this.toast.ShowError(error, false);
    super.handleError(error);
  }
}
