import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BsusResult } from '../models/bsus-result.model';
import { SoftwareProduct } from '../models/software-product';

@Injectable({
  providedIn: 'root'
})
export class SoftwareProductService {
  private products: ReplaySubject<SoftwareProduct[]> = new ReplaySubject<SoftwareProduct[]>(1);

  private initialize: boolean = true;

  constructor(private readonly http: HttpClient) { }

  public GetSoftwareProducts(renewCache: boolean = false): Observable<SoftwareProduct[]> {
    if (renewCache || this.initialize) {
      this.initialize = false;
      this.requestProducts();
    }
    return this.products.asObservable();
  }

  private requestProducts() {
    const path = `${environment.backend}softwareProduct`;
    return this.http
      .get<BsusResult<SoftwareProduct>>(path)
      .subscribe((next) => this.products.next(next.result));
  }
}