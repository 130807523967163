/* eslint-disable no-underscore-dangle */
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../auth/authentication.service';
import { LocaleService } from '../translation/locale.service';
import { UserService } from '../user/user.service';
import { Roles } from 'src/app/models/roles.enum';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public userLanguage = null;

  public showSelectLanguage: boolean = false;

  public username = null;

  public roles = Roles;

  constructor(
    private readonly authService: AuthenticationService,
    private readonly localeService: LocaleService,
    private readonly translateService: TranslateService,
    public readonly userService: UserService
  ) {}

  ngOnInit(): void {
    this.userLanguage = this.localeService.getUserLanguage();
    this.authService.getUserName().subscribe((next) => {
      this.username = next;
    });

    this.translateService.onLangChange.subscribe({
      next: () => {
        this.userLanguage = this.localeService.getUserLanguage();
      }
    });
  }

  public signIn() {
    this.authService.signIn();
  }

  public signOut() {
    this.authService.signOut();
  }

  public selectLanguage() {
    this.showSelectLanguage = true;
  }

  public closeSelectLanguage() {
    this.showSelectLanguage = false;
  }
}
