export enum Roles {
    PlatformRead = 'Platform.Read',
    PlatformWrite = 'Platform.Write',

    SoftwareProductRead = 'SoftwareProduct.Read',
    SoftwareProductWrite = 'SoftwareProduct.Write',

    SoftwareRead = 'Software.Read',
    SoftwareWrite = 'Software.Write',
    SoftwareAdmin = 'Software.Admin'
}