/* eslint-disable @typescript-eslint/indent */
/* eslint-disable class-methods-use-this */
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams
} from '@ngx-translate/core';

export class DefaultMissingTranslationHandler
  implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams): any {
    return this.getLastKey(params.key);
  }

  private getLastKey(keyChain: string): string {
    const lastIndex = keyChain.lastIndexOf('.');
    const lastKey = keyChain.substring(lastIndex + 1);
    const words = lastKey.split('_');
    const friendlyWords = [];
    words.forEach((word) => {
      let friendlyWord = word.toLowerCase();
      friendlyWord =
        friendlyWord.charAt(0).toUpperCase() + friendlyWord.slice(1);
      friendlyWords.push(friendlyWord);
    });
    return friendlyWords.join(' ');
  }
}
