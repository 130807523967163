<div class="k-overlay" *ngIf="showAddReleaseNote"></div>
<kendo-window *ngIf="showAddReleaseNote"
    (close)=closePopup()>
    <div class="popupContent">
        <div class="dropdownSelection">
            <div>
                <kendo-label [for]="langSelect" text="{{'overview.releasenotes.selectLanguage' | translate}}"></kendo-label>
            </div>
            <div>
                <kendo-dropdownlist #langSelect 
                    [data]="languageNames"
                    [filterable]="true"
                    (valueChange)="targetLanguageChanged($event)"
                    (filterChange)="handleFilter($event)"
                ></kendo-dropdownlist>
            </div>
        </div>
        <div class="dropdownSelection">
            <div>
                <kendo-label [for]="typeSelect" text="{{'overview.releasenotes.selectType' | translate}}"></kendo-label>
            </div>
            <div>
                <kendo-dropdownlist #typeSelect 
                    [data]="documentTypes"
                    [filterable]="false"                    
                    (valueChange)="targetTypeChanged($event)"
                ></kendo-dropdownlist>
            </div>           
        </div>
        <div id="dist">
            <kendo-label [for]="fileSelect" text="{{'overview.releasenotes.selectFile' | translate}}"></kendo-label>
        </div>
        <div>
            <kendo-upload #fileSelect [saveUrl]="uploadTargetUrl" [multiple]="false" [autoUpload]="false" [withCredentials]="false" (success)="uploadSuccess($event)"></kendo-upload>
        </div>

    </div>
    <div class="popup-buttons">
        <button kendoButton (click)="closePopup()">{{"common.close" | translate}}</button>
    </div>
</kendo-window>

<kendo-dialog
      [title]="'common.removeConfirmation.title' | translate"
      *ngIf="itemToRemove"
      (close)="confirmRemove(false)"
>   
    <p class="dialog-content">
        {{"common.removeConfirmation.message" | translate}} <strong>{{itemToRemove.fileName}}.{{itemToRemove.fileExtension}}</strong>?
    </p>
    <kendo-dialog-actions>
        <button kendoButton (click)="confirmRemove(false)">{{"common.no" | translate}}</button>
        <button kendoButton (click)="confirmRemove(true)" primary="true">{{"common.yes" | translate}}</button>
    </kendo-dialog-actions>
</kendo-dialog>

<div class="search">
    <div class="strech">
        <kendo-floatinglabel [text]="'common.search' | translate">
            <kendo-textbox
                [style.width.rem] = "25"
                [clearButton]="true"
                [value]="filterService.searchString"
                (valueChange)="filterService.searchStringChanged(gridService.data, $event)"
            ></kendo-textbox>
        </kendo-floatinglabel>
    </div>
    <div *ngIf="userService.hasAnyRole([roles.SoftwareAdmin, roles.SoftwareWrite]) | async" class="addButton">
        <button kendoButton (click)="addReleaseNote($event)">
            {{'overview.releasenotes.add' | translate}}
            <svg class="icon"><use xlink:href="/assets/icons/symbol-defs.svg#icon-icons-add"></use></svg>
        </button>
    </div>
</div>

<div class="filter" [ngClass]="{'selectableFilters': filterService.searchString}">
    <span (click)="filterService.filterElements('contains')">{{'common.filter.contains' | translate}}({{filterService.filter.contains}})</span>
    <span (click)="filterService.filterElements('doesnotcontain')">{{'common.filter.notContains' | translate}}({{filterService.filter.notContains}})</span>
    <span (click)="filterService.filterElements('eq')">{{'common.filter.isSame' | translate}}({{filterService.filter.isSame}})</span>
    <span (click)="filterService.filterElements('neq')">{{'common.filter.notSame' | translate}}({{filterService.filter.notSame}})</span>
    <span (click)="filterService.filterElements('startswith')">{{'common.filter.startsWith' | translate}}({{filterService.filter.startsWith}})</span>
    <span (click)="filterService.filterElements('endswith')">{{'common.filter.endsWith' | translate}}({{filterService.filter.endsWith}})</span>
</div>

<kendo-grid
    [data]="gridService.view"
    [loading]="releaseNotesService.loading" 
    [resizable]="true" 
    [pageSize]="gridService.state.take" 
    [skip]="gridService.state.skip" 
    [sortable]="true" 
    [sort]="gridService.state.sort" 
    [pageable]="true" 
    [navigable]="true"
    [scrollable]="'none'"
    [filter]="gridService.state.filter"
    [kendoGridReactiveEditing]="createFormGroup"
    [removeConfirmation]="removeConfirmation"
    [editService]="releaseNotesService"
    (dataStateChange)="gridService.dataStateChange($event)">
    <kendo-grid-column title="{{'overview.releasenotes.fileName' | translate}}" field="fileName"></kendo-grid-column>
    <kendo-grid-column title="{{'overview.releasenotes.fileExtension' | translate}}" field="fileExtension" width="220"></kendo-grid-column>
    <kendo-grid-column title="{{'overview.releasenotes.language' | translate}}" field="language" width="220">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div>{{getLanguage(dataItem.language)}}</div>
        </ng-template>
    </kendo-grid-column>    
    <kendo-grid-column title="{{'overview.releasenotes.type' | translate}}" field="type" width="220"></kendo-grid-column>
    <kendo-grid-column title="{{'overview.releasenotes.created' | translate}}" field="created" editor="date" [editable]="false" [format]="{datetime: 'short'}"></kendo-grid-column>
    <kendo-grid-command-column title="{{'common.edit' | translate}}" width="150">
        <ng-template kendoGridCellTemplate let-dataItem>
            <button class="edit-button blend-button" kendoButton (click)="showReleaseNote($event, dataItem)">
                <svg class="icon"><use xlink:href="/assets/icons/symbol-defs.svg#icon-icons-view"></use></svg>
            </button>
            <button class="edit-button" *ngIf="userService.hasAnyRole([roles.SoftwareAdmin, roles.SoftwareWrite]) | async" kendoGridRemoveCommand [title]="'common.remove' | translate">
                <svg class="icon"><use xlink:href="/assets/icons/symbol-defs.svg#icon-icons-delete"></use></svg>
            </button> 
        </ng-template>
    </kendo-grid-command-column>
</kendo-grid>
