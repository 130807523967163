export const kendo = {
  calendar: {
    today: 'Heute'
  },
  dateinput: {
    increment: 'Wert erhöhen',
    decrement: 'Wert verringern'
  },
  datepicker: {
    today: 'Heute',
    toggle: 'Kalender umschalten'
  },
  datetimepicker: {
    dateTab: 'Datum',
    dateTabLabel: 'Datum',
    timeTab: 'Zeit',
    timeTabLabel: 'Zeit',
    toggle: 'Umschalten',
    accept: 'Setzen',
    acceptLabel: 'Setzen',
    cancel: 'Abbrechen',
    cancelLabel: 'Abbrechen',
    now: 'JETZT',
    nowLabel: 'Jetzt',
    today: 'HEUTE'
  },
  dialog: {
    closeTitle: 'Schließen'
  },
  autocomplete: {
    noDataText: 'Keine Daten gefunden',
    clearTitle: 'Leeren'
  },
  combobox: {
    noDataText: 'Keine Daten gefunden',
    clearTitle: 'Leeren'
  },
  dropdownlist: {
    noDataText: 'Keine Daten gefunden',
    clearTitle: 'Leeren'
  },
  multiselect: {
    noDataText: 'Keine Daten gefunden',
    clearTitle: 'Leeren'
  },
  editor: {
    alignCenter: 'Center text',
    alignJustify: 'Justify',
    alignLeft: 'Align text left',
    alignRight: 'Align text right',
    backColor: 'Background color',
    bold: 'Bold',
    cleanFormatting: 'Clean formatting',
    createLink: 'Insert link',
    dialogApply: 'Apply',
    dialogCancel: 'Cancel',
    dialogInsert: 'Insert',
    dialogUpdate: 'Update',
    fileText: 'Text',
    fileTitle: 'Title',
    fileWebAddress: 'Web address',
    fontFamily: 'Select font family',
    fontSize: 'Select font size',
    foreColor: 'Color',
    format: 'Format',
    imageAltText: 'Alternate text',
    imageHeight: 'Height (px)',
    imageWebAddress: 'Web address',
    imageWidth: 'Width (px)',
    indent: 'Indent',
    insertFile: 'Insert file',
    insertImage: 'Insert image',
    insertOrderedList: 'Insert ordered list',
    insertUnorderedList: 'Insert unordered list',
    italic: 'Italic',
    linkOpenInNewWindow: 'Open link in new window',
    linkText: 'Text',
    linkTitle: 'Title',
    linkWebAddress: 'Web address',
    outdent: 'Outdent',
    redo: 'Redo',
    strikethrough: 'Strikethrough',
    subscript: 'Subscript',
    superscript: 'Superscript',
    underline: 'Underline',
    unlink: 'Remove Link',
    undo: 'Undo',
    viewSource: 'View source',
    insertTable: 'Insert Table',
    addColumnBefore: 'Add column before',
    addColumnAfter: 'Add column after',
    addRowBefore: 'Add row before',
    addRowAfter: 'Add row after',
    deleteColumn: 'Delete column',
    deleteRow: 'Delete row',
    deleteTable: 'Delete table'
  },
  grid: {
    groupPanelEmpty:
      'Ziehen Sie eine Spaltenüberschrift hierher, um nach dieser Spalte zu gruppieren',
    noRecords: 'Keine Datensätze verfügbar.',
    pagerFirstPage: 'Zur ersten Seite',
    pagerPreviousPage: 'Zur vorherigen Seite',
    pagerNextPage: 'Zur nächsten Seite',
    pagerLastPage: 'Zur letzten Seite',
    pagerPage: 'Seite',
    pagerOf: 'von',
    pagerItems: 'Elemente',
    pagerItemsPerPage: 'Elemente pro Seite',
    filter: 'Filter',
    filterEqOperator: 'ist gleich',
    filterNotEqOperator: 'ist nicht gleich',
    filterIsNullOperator: 'ist Null',
    filterIsNotNullOperator: 'ist nicht Null',
    filterIsEmptyOperator: 'ist leer',
    filterIsNotEmptyOperator: 'ist nicht leer',
    filterStartsWithOperator: 'beginnt mit',
    filterContainsOperator: 'beinhaltet',
    filterNotContainsOperator: 'beinhaltet nicht',
    filterEndsWithOperator: 'endet mit',
    filterGteOperator: 'ist größer als oder gleich',
    filterGtOperator: 'ist größer als',
    filterLteOperator: 'ist kleiner oder gleich als',
    filterLtOperator: 'ist kleiner als',
    filterIsTrue: 'ist richtig',
    filterIsFalse: 'ist falsch',
    filterBooleanAll: '(Alle)',
    filterAfterOrEqualOperator: 'ist gleich oder später als',
    filterAfterOperator: 'ist später als',
    filterBeforeOperator: 'ist früher als',
    filterBeforeOrEqualOperator: 'ist gleich oder früher als',
    filterFilterButton: 'Filtern',
    filterClearButton: 'Löschen',
    filterAndLogic: 'und',
    filterOrLogic: 'oder',
    filterDateToggle: 'Kalender umschalten',
    filterDateToday: 'Heute',
    filterNumericDecrement: 'Wert verringern',
    filterNumericIncrement: 'Wert erhöhen',
    loading: 'Laden',
    sort: 'Sortieren',
    columnMenu: 'Spaltenmenü',
    columns: 'Spalten',
    lock: 'Sperren',
    unlock: 'Entsperren',
    sortAscending: 'Aufsteigend sortieren',
    sortDescending: 'Absteigend sortieren',
    columnsApply: 'Übernehmen',
    columnsReset: 'Zurücksetzen',
    sortable: 'Sortable',
    sortedAscending: 'Sorted ascending',
    sortedDescending: 'Sorted descending',
    sortedDefault: 'Not sorted'
  },
  notification: {
    closeTitle: 'Schließen'
  },
  numerictextbox: {
    increment: 'Wert erhöhen',
    decrement: 'Wert verringern'
  },
  recurrenceeditor: {
    dailyInterval: 'Tag(e)',
    dailyRepeatEvery: ':Wiederholen an jedem=:',
    endAfter: 'Nach',
    endLabel: ':Beenden=:',
    endNever: 'Nie',
    endOccurrence: 'Anzahl Wiederholungen',
    endOn: 'Am',
    frequenciesDaily: 'Täglich',
    frequenciesMonthly: 'Monatlich',
    frequenciesNever: 'Nie',
    frequenciesWeekly: 'Wöchentlich',
    frequenciesYearly: 'Jährlich',
    monthlyDay: 'Tag',
    monthlyInterval: 'Monat(e)',
    monthlyRepeatEvery: ':Wiederholen an jedem=:',
    monthlyRepeatOn: ':Wiederholen am=:',
    offsetPositionsFourth: 'vierten',
    offsetPositionsLast: 'letzten',
    offsetPositionsSecond: 'zweiten',
    offsetPositionsThird: 'dritten',
    repeat: 'Wiederholen',
    weekdaysDay: 'Tag',
    weekdaysWeekday: 'Wochentag',
    weekdaysWeekendday: 'Tag am Wochenende',
    weeklyInterval: 'Woche(n)',
    weeklyRepeatEvery: ':Wiederholen an jedem=:',
    weeklyRepeatOn: ':Wiederholen am=:',
    yearlyInterval: 'Jahr(e)',
    yearlyOf: 'von',
    yearlyRepeatEvery: ':Wiederholen an jedem=:',
    yearlyRepeatOn: ':Wiederholen am=:'
  },
  scheduler: {
    agendaViewTitle: 'Agenda',
    allDay: 'Ganzer Tag',
    allEvents: 'All events',
    calendarToday: 'TODAY',
    cancel: 'Abbrechen',
    save: 'Save',
    editorEventTitle: 'Title',
    editorEventStart: 'Start',
    editorEventStartTimeZone: 'Start Time Zone',
    editorEventEnd: 'End',
    editorEventEndTimeZone: 'End Time Zone',
    dateHeader: 'Datum',
    dayViewTitle: 'Titel',
    deleteConfirmation: 'Möchten Sie diesen Termin wirklich löschen?',
    deleteDialogTitle: 'Termin löschen',
    deleteOccurrence: 'Delete current occurrence',
    deleteRecurringConfirmation:
      'Do you want to delete only this event occurrence or the whole series?',
    deleteRecurringDialogTitle:
      'Möchten Sie nur diesen Termin oder alle Wiederholungen löschen?',
    deleteSeries: 'Alle Wiederholungen des Termins löschen',
    deleteTitle: 'Termin löschen',
    destroy: 'Löschen',
    editOccurrence: 'Aktuelles Ereignis bearbeiten',
    editorEventAllDay: 'Ganztägiger Termin',
    editorEventDescription: 'Beschreibung',
    editorEventSeparateTimeZones:
      'Unterschiedliche Start- und Endzeitzonen benutzen',
    editorEventTimeZone: 'Zeitzone Ende',
    editorTitle: 'Titel',
    editRecurringConfirmation:
      'Möchten Sie nur diesen Termin oder alle Wiederholungen bearbeiten?',
    editRecurringDialogTitle: 'Wiederholungseintrag bearbeiten',
    editSeries: 'Serie bearbeiten',
    eventHeader: 'Termin',
    monthViewTitle: 'Monat',
    multiDayViewTitle: 'Multi-Day',
    nextTitle: 'Next',
    previousTitle: 'Previous',
    recurrenceEditorDailyInterval: 'Tag(e)',
    recurrenceEditorDailyRepeatEvery: ':Wiederholen an jedem=:',
    recurrenceEditorEndAfter: 'After',
    recurrenceEditorEndLabel: 'End',
    recurrenceEditorEndNever: 'Never',
    recurrenceEditorEndOccurrence: 'Anzahl Wiederholungen',
    recurrenceEditorEndOn: 'On',
    recurrenceEditorFrequenciesDaily: 'Täglich',
    recurrenceEditorFrequenciesMonthly: 'Monatlich',
    recurrenceEditorFrequenciesNever: 'Nie',
    recurrenceEditorFrequenciesWeekly: 'Wöchentlich',
    recurrenceEditorFrequenciesYearly: 'Jährlich',
    recurrenceEditorMonthlyDay: 'Tag',
    recurrenceEditorMonthlyInterval: 'Monat(e)',
    recurrenceEditorMonthlyRepeatEvery: ':Wiederholen an jedem=:',
    recurrenceEditorMonthlyRepeatOn: ':Wiederholen am=:',
    recurrenceEditorOffsetPositionsFirst: 'ersten',
    recurrenceEditorOffsetPositionsFourth: 'vierten',
    recurrenceEditorOffsetPositionsLast: 'letzten',
    recurrenceEditorOffsetPositionsSecond: 'zweiten',
    recurrenceEditorOffsetPositionsThird: 'dritten',
    recurrenceEditorRepeat: 'Wiederholen',
    recurrenceEditorWeekdaysDay: 'Tag',
    recurrenceEditorWeekdaysWeekday: 'Wochentag',
    recurrenceEditorWeekdaysWeekendday: 'Weekend Day',
    recurrenceEditorWeeklyInterval: 'Woche(n)',
    recurrenceEditorWeeklyRepeatEvery: ':Wiederholen an jedem=:',
    recurrenceEditorWeeklyRepeatOn: ':Wiederholen am=:',
    recurrenceEditorYearlyInterval: 'Jahr(e)',
    recurrenceEditorYearlyOf: 'of',
    recurrenceEditorYearlyRepeatEvery: ':Wiederholen an jedem=:',
    recurrenceEditorYearlyRepeatOn: ':Wiederholen am=:',
    showFullDay: 'Ganzen Tag anzeigen',
    showWorkDay: 'Geschäftszeiten anzeigen',
    timeHeader: 'Zeit',
    timelineMonthViewTitle: 'Timeline Month',
    timelineViewTitle: 'Timeline',
    timelineWeekViewTitle: 'Timeline Week',
    today: 'Heute',
    weekViewTitle: 'Week',
    workWeekViewTitle: 'Work Week'
  },
  slider: {
    increment: 'Wert erhöhen',
    decrement: 'Wert verringern',
    dragHandle: 'ziehen'
  },
  switch: {
    on: 'Ein',
    off: 'Aus'
  },
  timepicker: {
    accept: 'Setzen',
    acceptLabel: 'Setzen',
    cancel: 'Abbrechen',
    cancelLabel: 'Abbrechen',
    now: 'Jetzt',
    nowLabel: 'Jetzt',
    toggle: 'Zeitleiste umschalten'
  },
  tooltip: {
    closeTitle: 'Schließen'
  },
  treelist: {
    groupPanelEmpty:
      'Ziehen Sie eine Spaltenüberschrift hierher, um nach dieser Spalte zu gruppieren',
    noRecords: 'Keine Datensätze verfügbar.',
    pagerFirstPage: 'Zur ersten Seite',
    pagerPreviousPage: 'Zur vorherigen Seite',
    pagerNextPage: 'Zur nächsten Seite',
    pagerLastPage: 'Zur letzten Seite',
    pagerPage: 'Seite',
    pagerOf: 'von',
    pagerItemsTotal: 'Elemente',
    pagerItemsPerPage: 'Elemente pro Seite',
    filter: 'Filter',
    filterEqOperator: 'ist gleich',
    filterNotEqOperator: 'ist nicht gleich',
    filterIsNullOperator: 'ist Null',
    filterIsNotNullOperator: 'ist nicht Null',
    filterIsEmptyOperator: 'ist leer',
    filterIsNotEmptyOperator: 'ist nicht leer',
    filterStartsWithOperator: 'beginnt mit',
    filterContainsOperator: 'beinhaltet',
    filterNotContainsOperator: 'beinhaltet nicht',
    filterEndsWithOperator: 'endet mit',
    filterGteOperator: 'ist größer als oder gleich',
    filterGtOperator: 'ist größer als',
    filterLteOperator: 'ist kleiner oder gleich als',
    filterLtOperator: 'ist kleiner als',
    filterIsTrue: 'ist richtig',
    filterIsFalse: 'ist falsch',
    filterBooleanAll: '(Alle)',
    filterAfterOrEqualOperator: 'ist gleich oder später als',
    filterAfterOperator: 'ist später als',
    filterBeforeOperator: 'ist früher als',
    filterBeforeOrEqualOperator: 'ist gleich oder früher als',
    filterFilterButton: 'Filtern',
    filterClearButton: 'Löschen',
    filterAndLogic: 'und',
    filterOrLogic: 'oder',
    loading: 'Laden',
    sort: 'Sortieren',
    columnMenu: 'Spaltenmenü',
    columns: 'Spalten',
    lock: 'Sperren',
    unlock: 'Entsperren',
    sortAscending: 'Aufsteigend sortieren',
    sortDescending: 'Absteigend sortieren',
    columnsApply: 'Übernehmen',
    columnsReset: 'Zurücksetzen',
    sortable: 'Sortable',
    sortedAscending: 'Sorted ascending',
    sortedDescending: 'Sorted descending',
    sortedDefault: 'Not sorted'
  },
  upload: {
    cancel: 'Beenden',
    clearSelectedFiles: 'Leeren',
    dropFilesHere: 'Dateien zum Hochladen hierhin ziehen',
    headerStatusUploaded: 'Hochgeladen',
    headerStatusUploading: 'Hochladen...',
    invalidFileExtension: 'Dateityp nicht erlaubt.',
    invalidMaxFileSize: 'Datei ist zu groß.',
    invalidMinFileSize: 'Datei ist zu klein.',
    remove: 'Löschen',
    retry: 'Wiederholen',
    select: 'Datei(en) auswählen...',
    uploadSelectedFiles: 'Datei(en) hochladen',
    externalDropFilesHere: 'Drag and drop files here to upload',
    filesBatchStatus: 'files',
    filesBatchStatusFailed: 'files failed to upload.',
    filesBatchStatusUploaded: 'files successfully uploaded.',
    fileStatusFailed: 'File failed to upload.',
    fileStatusUploaded: 'File successfully uploaded.',
    headerStatusPaused: 'Paused'
  },
  window: {
    closeTitle: 'Schließen',
    restoreTitle: 'Wiederherstellen',
    maximizeTitle: 'Maximieren',
    minimizeTitle: 'Minimieren'
  }
};
