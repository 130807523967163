/* eslint-disable class-methods-use-this */
/* eslint-disable no-param-reassign */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EditService } from '@progress/kendo-angular-grid';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { BsusResult } from 'src/app/models/bsus-result.model';
import { Platform } from 'src/app/models/platform.model';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PlatformsService implements EditService {
  constructor(private readonly http: HttpClient,
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService) {}

  public loading: boolean = false;

  private initialize: boolean = true;
  private subject: ReplaySubject<Platform[]> = new ReplaySubject<Platform[]>(1);

  public getPlatforms(renewCache: boolean = false): Observable<Platform[]> {
    if(this.initialize || renewCache){
      this.requestPlatforms();
      this.initialize = false;
    }
    return this.subject.asObservable();
  }

  private requestPlatforms(): void{
    this.loading = true;
    const path = `${environment.backend}platform`;

    this.http.get<BsusResult<Platform>>(path).pipe(
      map((next) => next.result),
      map((next) =>
        next.map((x) => {
          x.created = x.created ? new Date(x.created) : null;
          x.modified = x.modified ? new Date(x.modified) : null;
          return x;
        })
      )
    ).subscribe({
      next: (next) => this.subject.next(next),
      error: (error) => {
        this.loading = false;
      },
      complete: () => this.loading = false
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public create(item: Platform): void {
    this.loading = true;
    const path = `${environment.backend}platform`;
    const body = {
      name: item.name,
      description: item.description,
      productHierarchyId: item.productHierarchyId
    };
    const message = item.name + this.translateService.instant('common.toastMessages.addSuccess');

    this.http.post(path, body).pipe(finalize(() => this.requestPlatforms())).subscribe({
      complete: () => this.toastService.ShowSuccess(message, false)
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public update(item: Platform): void {
    this.loading = true;
    const path = `${environment.backend}platform`;
    const body = {
      id: item.id,
      name: item.name,
      description: item.description,
      productHierarchyId: item.productHierarchyId
    };
    const message = item.name + this.translateService.instant('common.toastMessages.editSuccess');

    this.http.patch(path, body).pipe(finalize(() => this.requestPlatforms())).subscribe({
      complete: () => this.toastService.ShowSuccess(message, false)
    });
  }

  public remove(item: Platform): void {
    this.loading = true;
    const path = `${environment.backend}platform?platformName=${encodeURIComponent(item.name)}`;
    const message = item.name + this.translateService.instant('common.toastMessages.removeSuccess');

    this.http.delete(path).pipe(finalize(() => this.requestPlatforms())).subscribe({
      complete: () => this.toastService.ShowSuccess(message, false)
    });
  }

  public assignValues(target: Platform, source: Platform): void {
    Object.assign(target, source);
  }
}
