import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BreadCrumbItem } from '@progress/kendo-angular-navigation';
import { title } from 'process';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  public items: BreadCrumbItem[] = [
    {
      title: 'Home',
      icon: 'home',
      text: ''
    }
  ];

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.readRoute();
    this.readTranslation();
  }

  private readRoute(): void {
    this.router.events.subscribe(() => {
      this.buildItems();
    });
  }

  private readTranslation() {
    this.translateService.onLangChange.subscribe(() => this.buildItems());
  }

  private buildItems() {
    const route = this.router.url;
    const queryStringIndex = route.indexOf('?');
    
    let path = route;
    let queryString = ''

    if (queryStringIndex !== -1) {
      path = route.substring(0, queryStringIndex);
      queryString = route.substring(queryStringIndex+1);
    }

    const items = path
      .split('/')
      .filter(Boolean)
      .map((segment, i, arr) => {
        return {
          text: this.translateService.instant(`navigation.${segment}`),
          title: segment
        } as BreadCrumbItem;
      });

    //add software name to breadcrumb on details page
    const lastItemTitle = items[items.length - 1]?.title;
    if(lastItemTitle && lastItemTitle.localeCompare('details', undefined, { sensitivity: 'base' }) === 0) {
      let queryItems = decodeURIComponent(queryString).split('&')
      queryItems.forEach(queryItem => {
        let keyValuePair = queryItem.split('=');
        if(keyValuePair[0].localeCompare('software', undefined, { sensitivity: 'base' }) === 0) {
          items.push({
            text: keyValuePair[1],
            title: keyValuePair[1]
          } as BreadCrumbItem);
        }
      })
    }

    items.unshift({
      title: '',
      icon: 'home',
      text: ''
    } as BreadCrumbItem);

    this.items = items;
  }

  public itemClick(item: BreadCrumbItem) {
    const selectedItemIndex = this.items.findIndex(
      (i) => i.title === item.title
    );
    const url = this.items
      .slice(0, selectedItemIndex + 1)
      .map((i) => i.title.toLowerCase());
    this.router.navigate(url);
  }
}
