import { MessageService } from '@progress/kendo-angular-l10n';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

@Injectable()
export class KendoTranslationService extends MessageService {
  constructor(private readonly translateService: TranslateService) {
    super();
  }

  public get(key: string): string {
    return this.translateService.instant(key);
  }
}
