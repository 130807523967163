/* eslint-disable no-plusplus */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Filter } from 'src/app/models/filter.model';
import { CategorySummary } from 'src/app/models/category-summary';
import { SoftwareSummary } from 'src/app/models/software-summary';
import { Category } from 'src/app/models/product-category.enum';
import { OverviewService } from './overview.service';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {
  constructor(
    private readonly overviewService: OverviewService,
    private readonly route: ActivatedRoute,
    private readonly router: Router
  ) {}

  public loading: boolean = false;

  public category = Category;

  public softwareList: CategorySummary[];

  public softwareView: CategorySummary[] = [];

  public searchString = '';

  public filter: Filter = {
    contains: 0,
    notContains: 0,
    startsWith: 0,
    endsWith: 0,
    isSame: 0,
    notSame: 0
  };

  ngOnInit(): void {
    this.loadSoftwareList();
  }

  private loadSoftwareList(): void {
    this.loading = true;
    this.overviewService.getSoftwareList().subscribe({
      next: (next) => {
        this.softwareList = next;
        this.softwareView = next;
      },
      error: () => {
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  public searchStringChanged(searchValue: string): void {
    this.searchString = searchValue ?? '';

    if (this.searchString === '') {
      this.filter = OverviewComponent.GetEmptyFilter();
      this.softwareView = this.softwareList;
      return;
    }

    this.processFilter();
  }

  public navigateSoftware(software: string): void {
    if (software != null) {
      this.router.navigate(['details'], {
        relativeTo: this.route,
        queryParams: {
          software
        },
        queryParamsHandling: 'merge'
      });
    }
  }

  private static GetEmptyFilter(): Filter {
    const filter: Filter = {
      contains: 0,
      endsWith: 0,
      isSame: 0,
      notContains: 0,
      notSame: 0,
      startsWith: 0
    };

    return filter;
  }

  public filterElements(filter: string): void {
    if(this.searchString === ''){
      return;
    }

    const result: CategorySummary[] = [];

    const searchString = this.searchString.toLowerCase();

    let comparison: (softwareName: string) => boolean;

    if (filter === 'eq') {
      comparison = (softwareName: string) => {
        return softwareName === searchString;
      };
    } else if (filter === 'neq') {
      comparison = (softwareName: string) => {
        return softwareName !== searchString;
      };
    } else if (filter === 'startswith') {
      comparison = (softwareName: string) => {
        return softwareName.startsWith(searchString);
      };
    } else if (filter === 'endswith') {
      comparison = (softwareName: string) => {
        return softwareName.endsWith(searchString);
      };
    } else if (filter === 'contains') {
      comparison = (softwareName: string) => {
        return softwareName.indexOf(searchString) !== -1;
      };
    } else {
      comparison = (softwareName: string) => {
        return softwareName.indexOf(searchString) === -1;
      };
    }

    this.softwareList.forEach((x) => {
      const list: SoftwareSummary[] = [];

      x.softwareSummaries.forEach((y) => {
        const ysoftwareName = y.softwareName.toLowerCase();

        if (comparison(ysoftwareName)) {
          list.push(y);
        }
      });

      if (list.length !== 0) {
        const productSummary: CategorySummary = {
          category: x.category,
          hasNewSoftware: x.hasNewSoftware,
          softwareSummaries: list
        };
        result.push(productSummary);
      }
    });

    this.softwareView = result;
  }

  private processFilter(): void {
    let resultIsSame: number = 0;
    let resultNotSame: number = 0;
    let resultContains: number = 0;
    let resultNotContains: number = 0;
    let resultStartsWith: number = 0;
    let resultEndsWith: number = 0;

    const searchString = this.searchString.toLowerCase();

    this.softwareList.forEach((x) => {
      x.softwareSummaries.forEach((y) => {
        const ysoftwareName = y.softwareName.toLowerCase();

        // equal
        if (ysoftwareName === searchString) {
          resultIsSame++;
        } else {
          resultNotSame++;
        }

        // contains
        if (ysoftwareName.indexOf(searchString) !== -1) {
          resultContains++;

          // startsWith can only be true if contains is statisfied
          if (ysoftwareName.startsWith(searchString)) {
            resultStartsWith++;
          }

          // endsWith can only be true if contains is statisfied
          if (ysoftwareName.endsWith(searchString)) {
            resultEndsWith++;
          }
        } else {
          resultNotContains++;
        }
      });
    });

    this.filter.isSame = resultIsSame;
    this.filter.notSame = resultNotSame;
    this.filter.startsWith = resultStartsWith;
    this.filter.endsWith = resultEndsWith;
    this.filter.contains = resultContains;
    this.filter.notContains = resultNotContains;
  }
}
