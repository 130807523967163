import { Injectable } from '@angular/core';
import { CldrIntlService, IntlService } from '@progress/kendo-angular-intl';
import { locales } from '../../../locale/locales';

@Injectable()
export class LocaleService {
  private locale: string;

  set angularLocale(value: string) {
    this.setUserLocale(value);
  }

  get angularLocale(): string {
    return this.getUserLocale();
  }

  private language: string;

  constructor(private readonly intlService: IntlService) {
    this.locale = localStorage.getItem('user.locale');
    if (this.locale == null || this.locale === '') {
      this.verifySupportedLocale(navigator.language);
    }
    this.language = this.locale.substring(0, 2);
    (this.intlService as CldrIntlService).localeId = this.locale;
  }

  public getUserLanguage(): string {
    return this.language;
  }

  public getUserLocale(): string {
    return this.locale;
  }

  public setUserLocale(locale: string) {
    localStorage.setItem('user.locale', locale);
    this.verifySupportedLocale(locale);
    this.language = this.locale.substring(0, 2);
    (this.intlService as CldrIntlService).localeId = this.locale;
  }

  private verifySupportedLocale(locale: string) {
    let find = locales.find((x) => x.key === locale);
    // find if the the language is supported without matching region
    if (find == null) {
      const lang = locale.substring(0, 2);
      find = locales.find((x) => x.key.substring(0, 2) === lang);
    }

    if (find == null) {
      // default if no match at all is found
      this.locale = 'en-US';
    } else {
      this.locale = find.key;
    }
  }
}
