import { Injectable } from '@angular/core';
import {
  PublicClientApplication,
  InteractionRequiredAuthError,
  AuthenticationResult,
  AccountInfo,
  SilentRequest
} from '@azure/msal-browser';
import { ReplaySubject, Observable, throwError, TimeoutError } from 'rxjs';
import { map, mergeMap, catchError, tap, take, timeout } from 'rxjs/operators';
import { msalConfig } from './msalConfig';
import { ToastService } from '../toast/toast.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private msalInstance = new PublicClientApplication(msalConfig);

  private userName: ReplaySubject<string> = new ReplaySubject<string>(1);

  constructor(private readonly toast: ToastService) {
    this.msalInstance
      .handleRedirectPromise()
      .then((resp) => this.handleResponse(resp))
      .catch((error) => {
        toast.ShowError(error, false);
      });
  }

  private handleResponse(resp: AuthenticationResult) {
    let accountObj: AccountInfo;
    if (resp !== null) {
      accountObj = resp.account;
    } else {
      const currentAccounts = this.msalInstance.getAllAccounts();
      if (currentAccounts === null) {
        // this.toast.ShowError('shared.auth.noAccounts');
      } else if (currentAccounts.length !== 1) {
        this.toast.ShowError('shared.auth.multipleAccounts');
      } else {
        const index = 0;
        accountObj = currentAccounts[index];
      }
    }
    if (accountObj != null) {
      this.userName.next(accountObj.username);
    }
  }

  public getUserName(): Observable<string> {
    return this.userName.asObservable();
  }

  public signOut() {
    this.msalInstance
      .logout()
      .catch((error) => this.toast.ShowError(error, false))
      .then(() => {
        this.userName.next(null);
      });
  }

  public signIn() {
    this.msalInstance.loginRedirect({
      scopes: []
    });
  }

  public getTokenRedirect(scopes: string[]): Observable<string> {
    let saverequest: SilentRequest = null;

    const observable = this.getUserName().pipe(
      take(1),
      timeout(3000),
      map((name) => {
        const request: SilentRequest = {
          account: this.msalInstance.getAccountByUsername(name),
          scopes
        };
        return request;
      }),
      tap((request) => {
        saverequest = request;
      }),
      mergeMap(async (request) => {
        return this.msalInstance.acquireTokenSilent(request);
      }),
      map((authResult) => {
        return authResult.accessToken;
      }),
      catchError((e) => {
        this.msalInstance
          .acquireTokenRedirect(saverequest)
          .catch((error) => this.toast.ShowError(error, false));

        return throwError(() => e);
      })
    );
    return observable;
  }
}
