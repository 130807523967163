import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from '@progress/kendo-angular-l10n';
import { LocaleService } from './shared/translation/locale.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Bizerba Software Hub';

  constructor(
    private readonly translateService: TranslateService,
    private readonly kendotranslationService: MessageService,
    private readonly localeService: LocaleService
  ) {
    this.initializeTranslation();
  }

  private initializeTranslation() {
    this.translateService.setDefaultLang('en');
    this.translateService.use(this.localeService.getUserLanguage());
    this.translateService.onLangChange.subscribe({
      next: () => this.kendotranslationService.notify()
    });
  }
}
