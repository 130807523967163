<kendo-window *ngIf="showEditPopup"
    [minWidth]="600"
    [top]="20"
    [title]="(isNew ? 'softwareProduct.details.add' : 'softwareProduct.details.edit') | translate"
    (close)=closePopup()>
    <div class="popupContent">
        <form novalidate class="k-form" [formGroup]="formGroup">
            <kendo-formfield>
                <kendo-label [for]="language" text="{{'softwareProduct.details.language' | translate}}*"></kendo-label>
                <kendo-dropdownlist #language
                    formControlName="language"
                    [filterable]="true"
                    [data]="languages"
                    [textField]="'name'"
                    [valueField]="'code'"
                    [valuePrimitive]="true"
                    (filterChange)="handleFilter($event)"
                    required
                ></kendo-dropdownlist>
            </kendo-formfield>
            <kendo-formfield>
                <kendo-floatinglabel text="{{'softwareProduct.details.description' | translate}}">
                    <textarea kendoTextArea formControlName="value"></textarea>
                </kendo-floatinglabel>
            </kendo-formfield>
            <br>* {{'common.requiredFields' | translate}}
        </form>
    </div>
    <div class="popup-buttons">
        <button kendoButton (click)="savePopup()">{{"common.save" | translate}}</button>
        <button kendoButton (click)="closePopup()">{{"common.close" | translate}}</button>
    </div>
</kendo-window>
<div class="search">
    <div class="strech">
        <kendo-floatinglabel [text]="'common.search' | translate">
            <kendo-textbox
                [style.width.rem] = "25"
                [clearButton]="true"
                [value]="filterService.searchString"
                (valueChange)="filterService.searchStringChanged(this.gridService.data, $event)"
            ></kendo-textbox>
        </kendo-floatinglabel>
    </div>
    <div class="addButton">
        <button kendoButton (click)="addButtonClicked()">
            {{'softwareProduct.details.add' | translate}}
            <svg class="icon"><use xlink:href="/assets/icons/symbol-defs.svg#icon-icons-add"></use></svg>
        </button>
    </div>
</div>

<div class="filter" [ngClass]="{'selectableFilters': filterService.searchString}">
    <span (click)="filterService.filterElements('contains')">{{'common.filter.contains' | translate}}({{filterService.filter.contains}})</span>
    <span (click)="filterService.filterElements('doesnotcontain')">{{'common.filter.notContains' | translate}}({{filterService.filter.notContains}})</span>
    <span (click)="filterService.filterElements('eq')">{{'common.filter.isSame' | translate}}({{filterService.filter.isSame}})</span>
    <span (click)="filterService.filterElements('neq')">{{'common.filter.notSame' | translate}}({{filterService.filter.notSame}})</span>
    <span (click)="filterService.filterElements('startswith')">{{'common.filter.startsWith' | translate}}({{filterService.filter.startsWith}})</span>
    <span (click)="filterService.filterElements('endswith')">{{'common.filter.endsWith' | translate}}({{filterService.filter.endsWith}})</span>
</div>

<kendo-grid #grid
    [data]="gridService.view" 
    [loading]="softwareProductsDetailsService.loading" 
    [resizable]="true" 
    [pageSize]="gridService.state.take" 
    [skip]="gridService.state.skip" 
    [sortable]="true" 
    [sort]="gridService.state.sort" 
    [pageable]="true" 
    [navigable]="true"
    [scrollable]="'none'"
    [kendoGridReactiveEditing]="createFormGroup"
    [editService]="softwareProductsDetailsService"
    (dataStateChange)="gridService.dataStateChange($event)"
    (edit)="editHandler($event)">
    <kendo-grid-column title="{{'softwareProduct.details.language' | translate}}" field="languageName" [width]="120" [editable]="false"></kendo-grid-column>
    <kendo-grid-column title="{{'softwareProduct.details.description' | translate}}" field="value" [editable]="false"></kendo-grid-column>
    <kendo-grid-command-column title="{{'common.edit' | translate}}" width="120">
        <ng-template kendoGridCellTemplate let-dataItem let-isNew>
            <div class="center">
                <button kendoGridEditCommand>
                    <svg class="icon"><use xlink:href="/assets/icons/symbol-defs.svg#icon-icons-edit"></use></svg>
                </button>
            </div>
        </ng-template>
    </kendo-grid-command-column>
    <kendo-grid-command-column title="{{'common.remove' | translate}}" width="120" [hidden]="true">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="center">
                <button kendoGridRemoveCommand>
                    <svg class="icon"><use xlink:href="/assets/icons/symbol-defs.svg#icon-icons-delete"></use></svg>
                </button>    
            </div>
        </ng-template>
    </kendo-grid-command-column>
</kendo-grid>
