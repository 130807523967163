import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { CategorySummary } from 'src/app/models/category-summary';
import { BsusResult } from '../../models/bsus-result.model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OverviewService {
  constructor(private readonly http: HttpClient) {}

  public getSoftwareList(): Observable<CategorySummary[]> {
    const path = `${environment.backend}ui/overview`;

    return this.http
      .get<BsusResult<CategorySummary>>(path)
      .pipe(map((next) => next.result));
  }
}
