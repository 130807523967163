import { kendo } from './de-DE.kendo';

export const de = {
  kendo,
  common: {
    language: 'Sprache',
    remove: 'Löschen',
    next: 'Weiter',
    add: 'Hinzufügen',
    or: 'oder',
    loading: 'loading...',
    selectLanguage: 'Select Language:',
    search: 'Suche',
    signIn: 'Anmelden',
    signOut: 'Abmelden',
    edit: 'Bearbeiten',
    save: 'Speichern',
    cancel: 'Abbrechen',
    close: 'Schließen',
    requiredFields: 'Pflichtfelder',
    user: 'Benutzer',
    download: 'Download',
    filter: {
      startsWith: 'beginnt mit',
      endsWith: 'endet mit',
      notSame: 'ist nicht gleich',
      isSame: 'ist gleich',
      contains: 'beinhaltet',
      notContains: 'beinhaltet nicht'
    },
    documents: 'Dokumente',
    yes: 'Ja',
    no: 'Nein',
    toastMessages: {
      editSuccess:' wurde erfolgreich geändert',
      addSuccess: ' wurde erfoglreich hinzugefügt',
      removeSuccess: ' wurde erfolgreich entfernt'
    },
    removeConfirmation: {
      message: 'Möchten Sie das folgende Element sicher löschen:',
      title: 'Element löschen'
    }
  },
  navigation: {
    releases: 'Releases',
    settings: 'Einstellungen',
    platforms: 'Plattformen',
    softwareProducts: 'Software Produkte'
  },
  shared: {
    interceptors: {
      serverError: 'Der Server hat den Fehler {{statusCode}} zurückgegeben',
      clientError: 'Ein Client Fehler ist aufgetreten'
    },
    auth: {
      multipleAccounts: 'Mehrere Accounts erkannt',
      notAuthenticated: 'Kein User ist angemeldet'
    }
  },
  overview: {
    select_software: 'Software wählen',
    details: {
      displayName: 'Anzeigename',
      version: 'Version',
      createDate: 'Erstellt',
      availableFrom: 'Verfügbar ab',
      availableUntil: 'Verfügbar bis',
      noAuthentication: 'Lizenzfreie Software',
      description: 'Beschreibung',
      edit: 'Release bearbeiten',
      add: 'Release hinzufügen',
      packageType: 'Paket Typ',
      releaseType: 'Release Typ',
      released: 'Freigabe',
      platforms: 'Plattformen',
      languages: 'Sprachen',
      countries: 'Länder',
      documents: 'Dokumente',
      size: 'Größe',
      createdBy: 'Erstellt von',
      created: 'Erstellt',
      modifiedBy: 'Geändert von',
      modified:'Geändert',
      warning:'Warnung/Hinweis'
    },
    releasenotes: {
      add: 'Dokument hinzufügen',
      created: 'Erstellt',
      language: 'Sprache',
      fileExtension: 'Dateityp',
      fileName: 'Dateiname',
      selectLanguage: 'Sprache wählen:',
      selectFile: 'Datei wählen:',
      selectType: 'Dokumentart wählen:',
      view: 'Anzeigen',
      overwriteWarning: '!!!ACHTUNG!!! Für diese Sprache existiert bereits ein Dokument. Bei einem Upload wird das bestehende Dokument überschrieben!'
    }
  },
  platform: {
    name: 'Name',
    description: 'Beschreibung',
    modified: 'Geändert',
    modifiedBy: 'Geändert von',
    created: 'Erstellt',
    createdBy: 'Erstellt von',
    add: 'Plattform hinzufügen',
    edit: 'Plattform bearbeiten',
    productHierarchyId: "Produkthierarchie Id",
    productHierarchyIdPlaceholder: "SAP Produkthierarchie Id"
  },
  softwareProduct: {
    language: 'Sprache',
    category: 'Kategorie',
    packageinfoId: 'PackageinfoId',
    description: 'Beschreibung',
    add: 'Software Produkt hinzufügen',
    edit: 'Software Produkt bearbeiten',
    details: {
      language: 'Sprache',
      description: 'Beschreibung',
      add: 'Sprache hinzufügen',
      edit: 'Software Beschreibung bearbeiten'
    }
  },
  settings: {
    daysShowNew:
      'Neue Software Releases werden für X Tage nach Upload als neu angezeigt:',
    platforms:
      'Ich möchte über neue Software für folgende Platformen informiert werden:'
  }
};
