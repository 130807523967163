import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { PlatformService } from 'src/app/shared/platform/platform.service';
import { UserService } from 'src/app/shared/user/user.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  constructor(
    private readonly userService: UserService,
    private readonly platformService: PlatformService
  ) {}

  public days: number;

  public platforms: string[] = [];

  public selectedPlatforms: string[];

  ngOnInit(): void {
    this.getPlatforms();
    this.getUser();
  }

  private getPlatforms(): void {
    this.platformService.GetPlatforms().subscribe({
      next: (next) => {
        this.platforms = next.map((x) => x.name);
      }
    });
  }

  private getUser(): void {
    this.userService.GetUser().subscribe({
      next: (next) => {
        this.selectedPlatforms = next.platformNotifications;
        this.days = next.showNewDays;
      }
    });
  }

  public save() {
    const user: User = {
      showNewDays: this.days,
      platformNotifications: this.selectedPlatforms
    };
    this.userService.UpdateUser(user);
  }
}
