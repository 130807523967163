<div class="wrapper">
<div class="search">
    <kendo-floatinglabel [text]="'common.search' | translate">
        <kendo-textbox
            [style.width.rem] = "25"
            [clearButton]="true"
            [value]="searchString"
            (valueChange)="searchStringChanged($event)"
        ></kendo-textbox>
    </kendo-floatinglabel>
</div>

<div class="filter" [ngClass]="{'selectableFilters': searchString}">
    <span (click)="filterElements('contains')">{{'common.filter.contains' | translate}}({{filter.contains}})</span>
    <span (click)="filterElements('doesnotcontain')">{{'common.filter.notContains' | translate}}({{filter.notContains}})</span>
    <span (click)="filterElements('eq')">{{'common.filter.isSame' | translate}}({{filter.isSame}})</span>
    <span (click)="filterElements('neq')">{{'common.filter.notSame' | translate}}({{filter.notSame}})</span>
    <span (click)="filterElements('startswith')">{{'common.filter.startsWith' | translate}}({{filter.startsWith}})</span>
    <span (click)="filterElements('endswith')">{{'common.filter.endsWith' | translate}}({{filter.endsWith}})</span>
</div>
   
<kendo-panelbar
    [expandMode]="'single'">
    <kendo-panelbar-item [title]="software.product" *ngFor="let software of softwareView">
        <ng-template kendoPanelBarItemTitle>
            {{category[software.category]}}
            <span><svg class="icon" *ngIf="software.hasNewSoftware"><use xlink:href="/assets/icons/symbol-defs.svg#icon-icons-new"></use></svg></span>
        </ng-template>
        <ng-template kendoPanelBarContent >
            <div *ngFor="let software2 of software.softwareSummaries" class="item" (click)="navigateSoftware(software2.softwareName)">
                {{software2.softwareName}}
                <span><svg class="icon" *ngIf="software2.hasNewSoftware"><use xlink:href="/assets/icons/symbol-defs.svg#icon-icons-new"></use></svg></span>
            </div>
        </ng-template>
    </kendo-panelbar-item>
</kendo-panelbar>
</div>
