/* eslint-disable class-methods-use-this */
/* eslint-disable no-param-reassign */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EditService } from '@progress/kendo-angular-grid';
import { Observable, ReplaySubject } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { BsusResult } from 'src/app/models/bsus-result.model';
import { ReleaseNote } from 'src/app/models/release-note.model';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReleaseNotesService implements EditService{
  constructor(private readonly http: HttpClient,
    private readonly translateService: TranslateService,
    private readonly toastService: ToastService) {}

  public loading: boolean = false;

  private currentGuid: string = "";
  public readonly subject: ReplaySubject<ReleaseNote[]> = new ReplaySubject<ReleaseNote[]>(1);

  public get(guid: string, renewCache: boolean = false): Observable<ReleaseNote[]> {
    if(this.currentGuid !== guid || renewCache === true){
      this.currentGuid = guid;
      this.requestReleaseNotes();
    }
    return this.subject.asObservable();
  }

  private requestReleaseNotes(): void{
    const path = `${environment.backend}software/${this.currentGuid}/releaseNotes`;
    this.loading = true;
    this.http.get<BsusResult<ReleaseNote>>(path).pipe(
      map((next) => next.result),
      map((next) =>
        next.map((x) => {
          x.created = x.created ? new Date(x.created) : null;
          x.modified = x.modified ? new Date(x.modified) : null;
          return x;
        })
    )).subscribe({
      next: (next) => this.subject.next(next),
      error: (error) => {
        this.loading = false;
      },
      complete: () => this.loading = false
    });;
  }

  public getDocumentContentLink(id: number): string {
    const path = `${environment.backend}document/${id}/content`;
    return path;
  }

  public getDocumentUploadLink(id: string, lang: string, type: string): string {
    const path = `${environment.backend}software/${id}/releaseNotes?lang=${lang}&type=${type}`;
    return path;
  }

  create(item: any): void {
    throw new Error('Method not implemented.');
  }
  update(item: any): void {
    throw new Error('Method not implemented.');
  }
  remove(item: ReleaseNote): void {
    const message = item.fileName + '.' + item.fileExtension + this.translateService.instant('common.toastMessages.removeSuccess');

    this.http
      .delete(`${environment.backend}document?id=${item.id}`)
      .pipe(finalize(() => this.requestReleaseNotes())).subscribe({
        complete: () => this.toastService.ShowSuccess(message, false)
      });
  }
  assignValues(target: any, source: any): void {
    throw new Error('Method not implemented.');
  }
}
