import { Configuration } from '@azure/msal-browser';

export const msalConfig: Configuration = {
  auth: {
    clientId: 'd1260a3c-5c5e-40cd-84aa-323b2e957b00',
    authority:
      'https://login.microsoftonline.com/2898792b-c644-4207-bb78-a08f555def6c/',

    // redirect URI where the User gets redirected to by the Identity Provider after successful login
    redirectUri: `${window.location.origin}/`,

    // redirect URI where the User gets redirected to by the Identity Provider after successful logout
    postLogoutRedirectUri: `${window.location.origin}/`,

    // tl;dr: controls final destination of user after redirect logins
    // only used for redirect flows !!!!
    // this controls where the application routes the user after been successfully redirected
    // from the identitiy provider to redirectUri / postLogoutRedirectUri
    // true: navigates to location where login was started, disables handleRedirectCallback for successful response handling.
    // false: use handleRedirectCallback;
    navigateToLoginRequestUrl: true
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false
  },

  system: {
    windowHashTimeout: 60000,
    iframeHashTimeout: 6000,
    loadFrameTimeout: 0
  }
  // For more information about config see:
  // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
};
