import { Injectable } from '@angular/core';
import {
  NotificationService,
  NotificationSettings
} from '@progress/kendo-angular-notification';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  constructor(
    private readonly notificationService: NotificationService,
    private readonly translateService: TranslateService
  ) {}

  public ShowInfo(message: string, translate = true, param: object = null): void {
    this.Show('info', message, translate, param);
  }

  public ShowSuccess(message: string, translate = true, param: object = null): void {
    this.Show('success', message, translate, param);
  }

  public ShowError(message: string, translate = true, param: object = null): void {
    this.Show('error', message, translate, param, 10000);
  }

  public ShowWarning(message: string, translate = true, param: object = null): void {
    this.Show('warning', message, translate, param);
  }

  private Show(type: any, message: string, translate = true, param: object = null, hideAfter = 5000, closable = false) {
    let content = message;
    if (translate === true) {
      content = this.translateService.instant(message, param);
    }
    else if(param != null) {
      Object.entries(param).forEach(([key, value]) => {
        content = content.replace(`{{${key}}}`, value);
      });
    }

    const settings: NotificationSettings = {
      content,
      cssClass: 'button-notification',
      hideAfter,
      position: { horizontal: 'right', vertical: 'top' },
      animation: { type: 'fade', duration: 400 },
      type: { style: type, icon: true },
      closable
    };

    this.notificationService.show(settings);
  }
}
