import { Platform } from 'src/app/models/platform.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { BsusResult } from 'src/app/models/bsus-result.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {
  private platforms: ReplaySubject<Platform[]> = new ReplaySubject<Platform[]>(1);

  private initialize: boolean = true;

  constructor(private readonly http: HttpClient) {}

  public GetPlatforms(renewCache: boolean = false): Observable<Platform[]> {
    if (renewCache || this.initialize) {
      this.initialize = false;
      this.requestPlatforms();
    }
    return this.platforms.asObservable();
  }

  private requestPlatforms() {
    const path = `${environment.backend}platform`;
    return this.http
      .get<BsusResult<Platform>>(path)
      .subscribe((next) => this.platforms.next(next.result));
  }
}
