/* eslint-disable class-methods-use-this */
/* eslint-disable no-param-reassign */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EditService } from '@progress/kendo-angular-grid';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { BsusResult } from 'src/app/models/bsus-result.model';
import { Category } from 'src/app/models/product-category.enum';
import { SoftwareProduct } from 'src/app/models/software-product';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SoftwareProductsService implements EditService {
  constructor(private readonly http: HttpClient,
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService) {}

  public loading: boolean = false;

  private initialize: boolean = true;
  private subject: ReplaySubject<SoftwareProduct[]> = new ReplaySubject<SoftwareProduct[]>(1);

  public getSoftwareProducts(renewCache: boolean = false): Observable<SoftwareProduct[]> {
    if(this.initialize || renewCache){
      this.requestSoftwareProducts();
      this.initialize = false;
    }
    return this.subject.asObservable();
  }

  public requestSoftwareProducts(): void {
    this.loading = true;
    const path = `${environment.backend}softwareProduct`;

    this.http
      .get<BsusResult<SoftwareProduct>>(path)
      .pipe(map((next) => next.result)).subscribe({
        next: (next) => this.subject.next(next),
        error: (error) => {
          this.loading = false;
        },
        complete: () => this.loading = false
      });;
  }

  public create(item: SoftwareProduct): void {
    this.loading = true;
    const path = `${environment.backend}softwareProduct`;
    const category = Object.entries(Category).find(([key, value]) => value === item.category);
    const body = {
      name: item.name,
      description: item.description,
      category: category[0],
      packageinfoId: item.packageinfoId
    };
    const message = item.name + this.translateService.instant('common.toastMessages.addSuccess');

    this.http.post(path, body).pipe(finalize(() => this.requestSoftwareProducts())).subscribe({
      complete: () => this.toastService.ShowSuccess(message, false)
    });
  }

  public update(item: SoftwareProduct): void {
    this.loading = true;
    const path = `${environment.backend}softwareProduct`;
    const category = Object.entries(Category).find(([key, value]) => value === item.category);
    const body = {
      name: item.name,
      description: item.description,
      category: category[0],
      packageinfoId: item.packageinfoId
    };
    const message = item.name + this.translateService.instant('common.toastMessages.editSuccess');

    this.http.patch(path, body).pipe(finalize(() => this.requestSoftwareProducts())).subscribe({
      complete: () => this.toastService.ShowSuccess(message, false)
    });
  }

  public remove(item: SoftwareProduct): void {
    this.loading = true;
    const message = item.name + this.translateService.instant('common.toastMessages.removeSuccess');

    const path = `${environment.backend}softwareProduct?softwareProductName=${encodeURIComponent(item.name)}`;
    this.http.delete(path).pipe(finalize(() => this.requestSoftwareProducts())).subscribe({
      complete: () => this.toastService.ShowSuccess(message, false)
    });
  }

  public assignValues(target: SoftwareProduct, source: SoftwareProduct): void {
    Object.assign(target, source);
  }
}
