<div class="wrapper">

<div class="k-overlay" *ngIf="showEditPopup"></div>
<kendo-window *ngIf="showEditPopup"
    [minWidth]="600"
    [top]="20"
    [title]="(isNew ? 'overview.details.add' : 'overview.details.edit') | translate"
    (close)=closePopup()>
    <div class="popupContent">
        <form novalidate class="k-form" [formGroup]="formGroup">
            <kendo-formfield *ngIf="isNew">
                <kendo-label [for]="softwareNameSel" text="{{'overview.details.softwareProduct' | translate}}"></kendo-label>
                <kendo-dropdownlist #softwareNameSel
                formControlName="name"
                [data]="softwareProducts"
                [textField]="'name'"
                [valueField]="'name'"
                [valuePrimitive]="true"
                [kendoDropDownFilter]="filterSettings"
                required
                ></kendo-dropdownlist>
            </kendo-formfield>
            <kendo-formfield *ngIf="isNew">
                <kendo-floatinglabel text="{{'overview.details.version' | translate}}*">
                    <input kendoTextBox required formControlName="version"/>
                </kendo-floatinglabel>
            </kendo-formfield>
            <kendo-formfield *ngIf="isNew">
                <kendo-label [for]="releaseSelect" text="{{'overview.details.releaseType' | translate}}*"></kendo-label>
                <kendo-dropdownlist #releaseSelect 
                formControlName="releaseType"
                [data]="releaseTypes"
                required
                ></kendo-dropdownlist>
            </kendo-formfield>
            <kendo-formfield>
                <kendo-floatinglabel text="{{'overview.details.displayName' | translate}}">
                    <input kendoTextBox formControlName="displayName"/>
                </kendo-floatinglabel>
            </kendo-formfield>
            <kendo-formfield *ngIf="!isNew">
                <kendo-label [for]="platforms2" text="{{'overview.details.platforms' | translate}}*"></kendo-label>
                <kendo-multiselect #platforms2
                [data]="platforms"
                [textField]="'name'"
                [valueField]="'name'"
                [valuePrimitive]="true"
                [(ngModel)]="selectedPlatforms"
                [ngModelOptions]="{standalone: true}"
                [kendoDropDownFilter]="filterSettings"
                style="max-width: 580px"
                required>
                </kendo-multiselect>
            </kendo-formfield>
            <kendo-formfield *ngIf="isNew">
                <kendo-label [for]="platforms3" text="{{'overview.details.platforms' | translate}}*"></kendo-label>
                <kendo-multiselect #platforms3
                formControlName="platforms"
                [data]="platforms"
                [textField]="'name'"
                [valueField]="'name'"
                [valuePrimitive]="true"
                [kendoDropDownFilter]="filterSettings"
                style="max-width: 580px"
                required>
                </kendo-multiselect>
            </kendo-formfield>
            <kendo-formfield>
                <kendo-label [for]="countriesSelection" text="{{'overview.details.countries' | translate}}"></kendo-label>
                <kendo-multiselect #countriesSelection
                formControlName="countries"
                [data]="countryList"
                [textField]="'name'"
                [valueField]="'twoLetterCode'"
                [valuePrimitive]="true"
                [kendoDropDownFilter]="filterSettings"
                style="max-width: 580px">
                </kendo-multiselect>
            </kendo-formfield>
            <kendo-formfield>
                <kendo-label [for]="languageSelection" text="{{'overview.details.languages' | translate}}"></kendo-label>
                <kendo-multiselect #languageSelection
                formControlName="languages"
                [data]="languageList"
                [textField]="'name'"
                [valueField]="'code'"
                [valuePrimitive]="true"
                [kendoDropDownFilter]="filterSettings"
                style="max-width: 580px">
                </kendo-multiselect>
            </kendo-formfield>
            <kendo-formfield>
                <kendo-label [for]="box" text="{{'overview.details.noAuthentication' | translate}}"></kendo-label>
                <input type="checkbox" kendoCheckBox formControlName="noAuthentication" #box/>  
            </kendo-formfield>
            <kendo-formfield>
                <kendo-label [for]="box2" text="{{'overview.details.released' | translate}}"></kendo-label>
                <input type="checkbox" kendoCheckBox formControlName="released" #box2/>  
            </kendo-formfield>
            <kendo-formfield *ngIf="!isNew">
                <kendo-label [for]="date" text="{{'overview.details.availableFrom' | translate}}"></kendo-label>
                <kendo-datepicker formControlName="releaseDate" #date></kendo-datepicker>
            </kendo-formfield>
            <kendo-formfield *ngIf="!isNew">
                <kendo-label [for]="date2" text="{{'overview.details.availableUntil' | translate}}"></kendo-label>
                <kendo-datepicker formControlName="revokeDate" #date2></kendo-datepicker> 
            </kendo-formfield>
            <kendo-formfield>
                <kendo-label [for]="packageSelect" text="{{'overview.details.packageType' | translate}}*"></kendo-label>
                <kendo-dropdownlist #packageSelect 
                formControlName="packageType"
                [data]="packageTypes"
                required
            ></kendo-dropdownlist>
            </kendo-formfield>
            <kendo-formfield>
                <kendo-label [for]="warningSelect" text="{{'overview.details.warning' | translate}}"></kendo-label>
                <kendo-dropdownlist #warningSelect 
                formControlName="warningId"
                [data]="warnings"
                textField="message"
                valueField="warningId"
                [valuePrimitive]="true"
            >
                <ng-template kendoDropDownListItemTemplate let-data>
                    <svg *ngIf="data.type == 'critical'" class="icon warnicon"><use xlink:href="/assets/icons/symbol-defs.svg#icon-icons-cancelt"></use></svg>
                    <svg *ngIf="data.type == 'warning'" class="icon warnicon"><use xlink:href="/assets/icons/symbol-defs.svg#icon-icons-priority-outline"></use></svg>
                    <svg *ngIf="data.type == 'info'" class="icon warnicon"><use xlink:href="/assets/icons/symbol-defs.svg#icon-icons-info-outline"></use></svg>
                    {{data.message}}
                </ng-template>
            </kendo-dropdownlist>
            </kendo-formfield>
            <kendo-formfield *ngIf="isNew">
                <kendo-label [for]="fileSelect2" text="{{'overview.details.fileSelect' | translate}}"></kendo-label>
                <kendo-fileselect #fileSelect2
                    formControlName="downloadUrl" 
                    [multiple]="false"
                    [restrictions]="fileRestrictions"
                    required
                ></kendo-fileselect>
            </kendo-formfield>
            <kendo-formfield>
                <kendo-floatinglabel text="{{'overview.details.description' | translate}}">
                    <textarea kendoTextArea formControlName="description"></textarea>
                </kendo-floatinglabel>
            </kendo-formfield>
            <br>* {{'common.requiredFields' | translate}}
        </form>
    </div>
    <div class="popup-buttons">
        <button kendoButton (click)="savePopup()">{{"common.save" | translate}}</button>
        <button kendoButton (click)="closePopup()" primary="true">{{"common.close" | translate}}</button>
    </div>
</kendo-window>

<div class="k-overlay" *ngIf="showReleaseNotesPopup"></div>
<kendo-window *ngIf="showReleaseNotesPopup"
    [top]="20"
    title="{{'overview.details.documents' | translate}}"
    (close)=closeReleaseNotesPopup()>
    <div class= "popupReleaseNotesContent">
        <div class="grid">
            <app-release-notes [guid]="releaseNotesSoftware.guid"></app-release-notes>
        </div>
        <div class="griddistancer">&nbsp;</div>
    </div>
    <div class="popup-buttons">
        <button kendoButton (click)="closeReleaseNotesPopup()">{{"common.close" | translate}}</button>
    </div>
</kendo-window>

<div class="search">
    <div class="strech">
        <kendo-floatinglabel [text]="'common.search' | translate">
            <kendo-textbox
                [style.width.rem] = "25"
                [clearButton]="true"
                [value]="filterService.searchString"
                (valueChange)="filterService.searchStringChanged(gridService.data, $event)"
            ></kendo-textbox>
        </kendo-floatinglabel>
    </div>
    <div *ngIf="canEditSoftware() | async" class="addButton">
        <button kendoButton (click)="addButtonClicked()">
            {{'overview.details.add' | translate}}
            <svg class="icon"><use xlink:href="/assets/icons/symbol-defs.svg#icon-icons-add"></use></svg>
        </button>
    </div>
</div>

<kendo-dialog
      [title]="'common.removeConfirmation.title' | translate"
      *ngIf="itemToRemove"
      (close)="confirmRemove(false)"
>   
    <p class="dialog-content">
        {{"common.removeConfirmation.message" | translate}} <strong>{{itemToRemove.name}}</strong>?
    </p>
    <kendo-dialog-actions>
        <button kendoButton (click)="confirmRemove(false)" >{{"common.no" | translate}}</button>
        <button kendoButton (click)="confirmRemove(true)" themeColor="primary" >{{"common.yes" | translate}}</button>
    </kendo-dialog-actions>
</kendo-dialog>

<kendo-grid #grid
    [data]="gridService.view" 
    [style.height.%]="100" 
    [loading]="detailService.loading" 
    [resizable]="true" 
    [pageSize]="gridService.state.take" 
    [skip]="gridService.state.skip" 
    [sortable]="true" 
    [sort]="gridService.state.sort" 
    [pageable]="true" 
    [navigable]="true"
    [scrollable]="'none'"
    [kendoGridReactiveEditing]="createFormGroup"
    [editService]="detailService"
    [removeConfirmation]="removeConfirmation"
    (edit)="editHandler($event)"
    (dataStateChange)="gridService.dataStateChange($event)">
    <ng-template kendoGridToolbarTemplate>
        <div class="filter" [ngClass]="{'selectableFilters': filterService.searchString}">
            <span (click)="filterService.filterElements('contains')">{{'common.filter.contains' | translate}}({{filterService.filter.contains}})</span>
            <span (click)="filterService.filterElements('doesnotcontain')">{{'common.filter.notContains' | translate}}({{filterService.filter.notContains}})</span>
            <span (click)="filterService.filterElements('eq')">{{'common.filter.isSame' | translate}}({{filterService.filter.isSame}})</span>
            <span (click)="filterService.filterElements('neq')">{{'common.filter.notSame' | translate}}({{filterService.filter.notSame}})</span>
            <span (click)="filterService.filterElements('startswith')">{{'common.filter.startsWith' | translate}}({{filterService.filter.startsWith}})</span>
            <span (click)="filterService.filterElements('endswith')">{{'common.filter.endsWith' | translate}}({{filterService.filter.endsWith}})</span>
        </div>
        <kendo-grid-column-chooser></kendo-grid-column-chooser>
    </ng-template>
    <kendo-grid-column title="{{'overview.details.released' | translate}}" field="released" [editable]="false" [autoSize]="false" [width]="40">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div *ngIf="isReleased(dataItem); else notReleased" class="center">
                <svg class="icon released"><use xlink:href="/assets/icons/symbol-defs.svg#icon-icons-done"></use></svg>
            </div> 
            <ng-template #notReleased>
                <div class="center">
                    <svg class="icon not-released"><use xlink:href="/assets/icons/symbol-defs.svg#icon-icons-cancelt"></use></svg>
                </div>
            </ng-template>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{'overview.details.displayName' | translate}}" field="displayName" [hidden]="true" [editable]="false">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div [ngClass]="{'bold': markAsNew(dataItem.date)}">{{dataItem.displayName}}</div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{'overview.details.version' | translate}}" field="version" [editable]="false"></kendo-grid-column>
    <kendo-grid-column title="{{'overview.details.releaseType' | translate}}" field="releaseType" [editable]="false"></kendo-grid-column>
    <kendo-grid-column title="{{'overview.details.packageType' | translate}}" field="packageType" [editable]="false"></kendo-grid-column>
    <kendo-grid-column title="{{'overview.details.size' | translate}}" field="size" [editable]="false"></kendo-grid-column>
    <kendo-grid-column title="{{'overview.details.platforms' | translate}}" field="platforms" [editable]="false" [style]="{'white-space':'normal'}">
        <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.platforms.join(', ')}}
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{'overview.details.languages' | translate}}" field="languages" [editable]="false"></kendo-grid-column>
    <kendo-grid-column title="{{'overview.details.countries' | translate}}" field="countries" [editable]="false"></kendo-grid-column>
    <kendo-grid-column title="{{'overview.details.noAuthentication' | translate}}" field="noAuthentication" [hidden]="true" [editable]="false">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div *ngIf="dataItem.noAuthentication" class="center">
                <svg class="icon"><use xlink:href="/assets/icons/symbol-defs.svg#icon-icons-done"></use></svg>
            </div> 
            <div *ngIf="!dataItem.noAuthentication" class="center">
                <svg class="icon"><use xlink:href="/assets/icons/symbol-defs.svg#icon-icons-cancelt"></use></svg>
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{'overview.details.createDate' | translate}}" field="date" editor="date" [editable]="false" [hidden]="true" [format]="{datetime: 'short'}"></kendo-grid-column>
    <kendo-grid-column title="{{'overview.details.modified' | translate}}" field="modified" editor="date" [editable]="false" [hidden]="true" [format]="{datetime: 'short'}"></kendo-grid-column>
    <kendo-grid-column title="{{'overview.details.modifiedBy' | translate}}" field="modifiedBy" editor="date" [editable]="false" [hidden]="true" [format]="{datetime: 'short'}"></kendo-grid-column>
    <kendo-grid-column title="{{'overview.details.created' | translate}}" field="created" editor="date" [editable]="false" [format]="{datetime: 'short'}"></kendo-grid-column>
    <kendo-grid-column title="{{'overview.details.createdBy' | translate}}" field="createdBy" editor="date" [editable]="false" [format]="{datetime: 'short'}"></kendo-grid-column>
    <kendo-grid-command-column title="{{'common.edit' | translate}}" [autoSize]="false" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem let-isNew>
            <div class="center">
                <button class="edit-button blend-button" [title]="'common.download' | translate" kendoButton (click)="initiateDownload($event, dataItem)">
                    <svg class="icon"><use xlink:href="/assets/icons/symbol-defs.svg#icon-icons-download"></use></svg>
                </button>
                <button class="edit-button blend-button" [title]="'common.documents' | translate" kendoButton (click)="showReleaseNotes($event, dataItem)">
                    <svg class="icon"><use xlink:href="/assets/icons/symbol-defs.svg#icon-icons-manuals"></use></svg>
                </button>
                <button class="edit-button" *ngIf="canEditSoftware() | async" kendoGridEditCommand [title]="'common.edit' | translate">
                    <svg class="icon"><use xlink:href="/assets/icons/symbol-defs.svg#icon-icons-edit"></use></svg>
                </button>
                <button class="edit-button" *ngIf="canEditSoftware() | async" kendoGridRemoveCommand [title]="'common.remove' | translate">
                    <svg class="icon"><use xlink:href="/assets/icons/symbol-defs.svg#icon-icons-delete"></use></svg>
                </button> 
            </div>
        </ng-template>
    </kendo-grid-command-column>
</kendo-grid>
</div>