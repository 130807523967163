import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, ReplaySubject } from 'rxjs';
import { BsusResult } from 'src/app/models/bsus-result.model';
import { Warning } from 'src/app/models/warning.model';
import { environment } from 'src/environments/environment';
import { LocaleService } from '../translation/locale.service';

@Injectable({
  providedIn: 'root'
})
export class WarningService {
  private warnings: ReplaySubject<Warning[]> = new ReplaySubject<Warning[]>(1);

  private initialize: boolean = true;

  constructor(private readonly http: HttpClient,
              private readonly localeService: LocaleService,
              private readonly translateService: TranslateService) {

                this.translateService.onLangChange.subscribe(() => this.requestWarnings())
              }

  public GetWarnings(renewCache: boolean = false): Observable<Warning[]> {
    if (renewCache || this.initialize) {
      this.requestWarnings();
    }
    return this.warnings.asObservable();
  }

  private requestWarnings() {
    const path = `${environment.backend}warning?language=${this.localeService.getUserLanguage()}`;
    return this.http
      .get<BsusResult<Warning>>(path)
      .subscribe({
        next: (next) => {next.result.unshift({
          language: '',
          message: '-',
          warningId: null,
          type: null
        });
        this.warnings.next(next.result);
      }
    });
  }
}
