import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GroupResult, State, groupBy } from '@progress/kendo-data-query';
import { SearchSuggestion } from 'src/app/models/search-suggestion.model';
import { SuggestionType } from 'src/app/models/suggestion-type.enum';
import { PlatformService } from 'src/app/shared/platform/platform.service';
import { SoftwareProductService } from 'src/app/shared/software-product.service';

@Component({
  selector: 'app-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss']
})
export class SearchbarComponent implements OnInit {

  public view: GroupResult[] | SearchSuggestion[] = [];

  private historySuggestionList: string[] = [];
  private platformSuggestionList: string[] = [];
  private softwareSuggestionList: string[] = [];
  
  public showOverlay: boolean = false;

  public searchString: string = '';
  
  private readonly LOCAL_STORAGE_QUERIES_KEY: string = 'StoredQueries';
  private readonly MAX_QUERY_CACHE: number = 20;

  private formerQueries: Map<string, number>;
  
  constructor(private readonly router: Router,
    private readonly platformService: PlatformService,
    private readonly softwareProductService: SoftwareProductService) { }

  ngOnInit(): void {
    this.loadFormerSearchQueriesFromStorage();
    this.initializeSuggestionList();
  }

  public filterChanged(value: string){
    this.processSuggestionView(value);
  }

  public focusSearch(event) {
    this.showOverlay = true;
  }

  public open(event){
    this.showOverlay = true;
  }

  public blurSearch(event){
    this.showOverlay = false;
  }

  public keyPressed(event){
    if(event.which == 13){
      event.preventDefault();
      this.blurSearch(null);
      this.search();
    }
  }

  public valueChanged(event){
    this.searchString = event;    
    this.blurSearch(null);
    this.search();
  }

  public clickSearch(event){
    if(this.searchString !== '')
    {
      this.blurSearch(null);
      this.search();
    } 
  }

  public search(){
    if(this.searchString !== ''){
      this.formerQueries.set(this.searchString, new Date().getTime());
      this.saveFormerSearchQueriesToStorage();
      this.transformHistorytoSuggestions();
      this.router.navigate(['overview/details'], {
        queryParams: {
          searchString: this.searchString
        },
        queryParamsHandling: ''
      })
    }
  }

  private initializeSuggestionList(){

    this.transformHistorytoSuggestions();

    this.softwareProductService.GetSoftwareProducts().subscribe(
      next => { 
        this.softwareSuggestionList = next.map<string>(software => software.name)
      }
    );

    this.platformService.GetPlatforms().subscribe(
      next => { 
        this.platformSuggestionList = next.map<string>(platform => platform.name)
      }
    );
  }

  private transformHistorytoSuggestions(){      
    this.historySuggestionList = [];
    this.formerQueries.forEach((value, key) => {
      this.historySuggestionList.push(key)
    })
  }

  private processSuggestionView(value: string){
    const result: SearchSuggestion[] = [];
    const currentWordIndex = value.lastIndexOf(' ')+1;

    const currentWord = value.substring(currentWordIndex);
    const currentStub = value.substring(0, currentWordIndex);

    const historySuggestions = this.processHistorySuggestion(value);
    if(historySuggestions != null){
      result.push(...historySuggestions)
    }

    const softwareSuggestions = this.processSoftwareSuggestion(currentWord, currentStub);
    if(softwareSuggestions != null){
      result.push(...softwareSuggestions)
    }

    const platformSuggestions = this.processPlatformSuggestion(currentWord, currentStub);
    if(platformSuggestions != null){
      result.push(...platformSuggestions)
    }

    this.view = groupBy(result, [{field: 'category'}]);
  }

  private processHistorySuggestion(currentValue: string): SearchSuggestion[] {
    
    const suggestions = this.historySuggestionList
      .filter(x => x.toLowerCase().startsWith(currentValue.toLowerCase()));
    return this.toSearchSuggestion(suggestions, SuggestionType.HISTORY);
  }

  private processPlatformSuggestion(searchWord: string, currentStub: string): SearchSuggestion[] {
    const suggestions = this.platformSuggestionList
      .filter(x => x.toLowerCase().includes(searchWord.toLowerCase()))
      .map<string>(x => currentStub + x)
    return this.toSearchSuggestion(suggestions, SuggestionType.PLATFORM);
  }

  private processSoftwareSuggestion(searchWord: string, currentStub: string): SearchSuggestion[] {
    const suggestions = this.softwareSuggestionList
      .filter(x => x.toLowerCase().includes(searchWord.toLowerCase()))
      .map<string>(x => currentStub + x)
    return this.toSearchSuggestion(suggestions, SuggestionType.SOFTWARE);
  }

  private toSearchSuggestion(suggestions: string[], category: SuggestionType): SearchSuggestion[]{
    if(suggestions.length <= 0){
      return null;
    }
    const items = suggestions.map<SearchSuggestion>(x => {return {value: x, category}});
    return items;
  }
  
  private loadFormerSearchQueriesFromStorage(): void {
    const storageContent = JSON.parse(localStorage.getItem(this.LOCAL_STORAGE_QUERIES_KEY));
    this.formerQueries = new Map<string, number>(storageContent);
    this.sortFormerQueries();
  }

  private saveFormerSearchQueriesToStorage(): void {
    this.sortFormerQueries();

    if(this.formerQueries.size > this.MAX_QUERY_CACHE){
      const keys = [...this.formerQueries.keys()]
      //remove oldest entries
      for(let i=this.formerQueries.size-1; this.formerQueries.size - i > this.MAX_QUERY_CACHE; i--){
        this.formerQueries.delete(keys[i])
      }
    }

    const storageContent = JSON.stringify([...this.formerQueries.entries()])
    localStorage.setItem(this.LOCAL_STORAGE_QUERIES_KEY, storageContent); 
  }

  private sortFormerQueries(){
    this.formerQueries = new Map<string, number>([...this.formerQueries].sort(([, a], [, b]) => (a - b) * -1))
  }
}
