<ng-container *ngIf="supportedLocales && supportedLocales.length > 0">
    <div class="language-switch">
      <ng-container *ngFor="let availableLocale of supportedLocales; let last = last">
        <div class="language-entry-wrapper" [ngClass]="{
        'active': availableLocale.key === selectedLocale,
        'last': last
        }">
          <div class="language-entry col">
            <a (click)="changeLocale(availableLocale.key)"> 
              <ng-container>
                {{ availableLocale.name }}
              </ng-container>
            </a>
          </div>
        </div>
      </ng-container>
    </div>
</ng-container>
  