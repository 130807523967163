import { Injectable } from '@angular/core';
import { DataStateChangeEvent, GridComponent } from '@progress/kendo-angular-grid';
import {
  CompositeFilterDescriptor,
  DataResult,
  process,
  State
} from '@progress/kendo-data-query';

@Injectable()
export class GridService {

  public data: any[];
  
  public view: DataResult;

  public state: State = {
    take: 9,
    skip: 0,
  };

  private grid: GridComponent;

  constructor() { }

  public init(grid: GridComponent): void {
    this.grid = grid;
  }

  public processView(): void {
    this.view = process(this.data, this.state);
  }

  public dataStateChange(event: DataStateChangeEvent) {
    this.state.skip = event.skip;
    this.state.sort = event.sort;
    this.state.take = event.take;
    this.processView();
  }

  public filterChanged(filter: CompositeFilterDescriptor): void {
    this.state.filter = filter;
    this.state.skip = 0;
    this.processView();
  }
}
