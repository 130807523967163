<div class ="wrapper">
<div class="innerwrapper">
    <div>
        <kendo-label text="{{'settings.daysShowNew' | translate}}">
            <br/>
            <kendo-numerictextbox
                [(value)]="days"
                [format]="'n0'"
                [decimals]="0"
            >
            </kendo-numerictextbox>
        </kendo-label>
    </div>
    <div>
        <kendo-label text="{{'settings.platforms' | translate}}">
            <br/>
            <kendo-multiselect [data] = "platforms" [(value)]="selectedPlatforms" width="300px"></kendo-multiselect>
        </kendo-label>
    </div>
    <div class="buttons">
        <button kendoButton (click)="save()" >{{"common.save" | translate}}</button>
    </div>
</div>
</div>