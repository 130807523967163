import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

import { de } from '../../../locale/de';
import { en } from '../../../locale/en';

export class StaticTranslateLoader implements TranslateLoader {
  private translation = en;

  public getTranslation(lang: string): Observable<any> {
    switch (lang) {
      case 'en':
        this.translation = en;
        break;
      case 'de':
        this.translation = de;
        break;
      default:
        this.translation = en;
    }
    return of(this.translation);
  }
}
