import { Injectable, Input } from '@angular/core';
import { Filter } from 'src/app/models/filter.model';
import {
  CompositeFilterDescriptor,
  DataResult,
  process,
  State
} from '@progress/kendo-data-query';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class FilterService {

  get searchString(): string {
    return this._searchString
  }

  set searchString(value: string) {
    this._searchString = value;
  }

  private _searchString = '';

  public filter: Filter;

  public readonly gridFilter = new ReplaySubject<CompositeFilterDescriptor>();

  private fields: string[] = [];

  constructor() {
    this.resetFilter();
  }

  public setFields(fields: string[]): void {
    this.fields = fields;
  }

  public searchStringChanged(data: any[], searchValue: string): void {
    this._searchString = searchValue ?? '';

    if (this._searchString === '') {
      this.resetFilter();
      this.gridFilter.next(null);
      
    }
    else{
      this.processFilter(data);
    }
  }

  public filterElements(filter: string): void {
    if(this._searchString === ''){
      return;
    }
    
    let newFilter = this.GetFilter(filter);
    this.gridFilter.next(newFilter);    
  }

  //private

  public resetFilter(): void {
    this.filter = {
      contains: 0,
      notContains: 0,
      startsWith: 0,
      endsWith: 0,
      isSame: 0,
      notSame: 0
    };
  }

  public processFilter(data: any[]): void {
    let state: State = {
      filter: this.GetFilter('eq')
    };

    let result = process(data, state);
    this.filter.isSame = result.data.length;

    this.filter.notSame = data.length - this.filter.isSame;

    state = {
      filter: this.GetFilter('startswith')
    };
    result = process(data, state);
    this.filter.startsWith = result.data.length;

    state = {
      filter: this.GetFilter('endswith')
    };
    result = process(data, state);
    this.filter.endsWith = result.data.length;

    state = {
      filter: this.GetFilter('contains')
    };
    result = process(data, state);
    this.filter.contains = result.data.length;

    this.filter.notContains = data.length - this.filter.contains;
  }

  public GetFilter(comparator: string): CompositeFilterDescriptor {
    let logic: 'or' | 'and' = 'or';
    if (comparator === 'doesnotcontain' || comparator === 'neq') {
      logic = 'and';
    }

    const filter: CompositeFilterDescriptor = {
      logic,
      filters: []
    };

    this.fields.forEach(fieldName => {
      filter.filters.push({
        operator: comparator,
        value: this._searchString,
        field: fieldName
      });  
    });

    return filter;
  }
}
