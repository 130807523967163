import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { BsusResult } from 'src/app/models/bsus-result.model';
import { User } from 'src/app/models/user.model';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../toast/toast.service';
import { Roles } from 'src/app/models/roles.enum';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private user: ReplaySubject<User> = new ReplaySubject<User>(1);

  private initialize: boolean = true;

  constructor(private readonly http: HttpClient,
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService) {}

  public GetUser(renewCache: boolean = false): Observable<User> {
    if (renewCache || this.initialize) {
      this.requestUser();
    }
    return this.user.asObservable();
  }

  private requestUser() {
    this.initialize = false;
    const path = `${environment.backend}user`;
    return this.http
      .get<BsusResult<User>>(path)
      .subscribe((next) => this.user.next(next.result[0]));
  }

  public UpdateUser(user: User): void {
    const path = `${environment.backend}user`;
    const message = this.translateService.instant('common.settings') + this.translateService.instant('common.toastMessages.editSuccess');

    this.http
      .patch<BsusResult<User>>(path, user)
      .subscribe({
        next: (next) => this.user.next(next.result[0]),
        complete: () => this.toastService.ShowSuccess(message, false)
      });
  }

  public hasAnyRole(roles: Roles[]): Observable<boolean> {
    if (this.initialize) {
      this.requestUser();
    }
    return this.user.pipe(
      map(user => {
        return roles.some(role => {
          //for some reason the Map function "has()" is not working, so we have to do it the old way
          let result = false;
          user.roles.forEach(userRole => {
            if( userRole === role ){
              result = true;
            }
          });
          return result;
        });
      })
    );
  }
}
