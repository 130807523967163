/* eslint-disable class-methods-use-this */
/* eslint-disable no-param-reassign */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EditService } from '@progress/kendo-angular-grid';
import { Observable, ReplaySubject } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { BsusResult } from 'src/app/models/bsus-result.model';
import { SoftwareProduct } from 'src/app/models/software-product';
import { TranslatedString } from 'src/app/models/translated-string.model';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SoftwareProductsDetailsService implements EditService {
  constructor(private readonly http: HttpClient,
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService) {}

  private softwareProductName: string;
  public loading: boolean = false;

  public errorMessages: string[] = [];
  public success: boolean = false;

  private initialize: boolean = true;
  private subject: ReplaySubject<SoftwareProduct> = new ReplaySubject<SoftwareProduct>(1);

  public setSoftwareProduct(softwareProductName: string): void {
    this.softwareProductName = softwareProductName;
    this.initialize = true;
  }

  public getSoftwareProductDetails(renewCache: boolean = false): Observable<SoftwareProduct> {
    if(this.initialize || renewCache){
      this.requestSoftwareProductDetails();
      this.initialize = false;
    }
    return this.subject.asObservable();
  }

  public requestSoftwareProductDetails(): void{
    this.loading = true;
    const path = `${environment.backend}softwareProduct`;

    this.http
      .get<BsusResult<SoftwareProduct>>(path)
      .pipe(
        map((next) =>
          next.result.find((x) => x.name === this.softwareProductName)
        )
      ).subscribe({
        next: (next) => this.subject.next(next),
        error: (error) => {
          this.loading = false;
        },
        complete: () => this.loading = false
      });
  }

  public create(item: TranslatedString): void {
    this.loading = true;
    const path = `${environment.backend}softwareProduct`;

    const descriptions: { [key: string]: string } = {};
    descriptions[item.language] = item.value;
    const body = {
      name: this.softwareProductName,
      description: descriptions
    };

    this.http.patch(path, body).pipe(finalize(() => this.requestSoftwareProductDetails())).subscribe({
      error: (error) => {
        this.errorMessages.push(error);
        throw error;
      },
      complete: () => {
        this.success = true;
      }
    });
  }

  public update(item: TranslatedString): void {
    this.loading = true;
    const path = `${environment.backend}softwareProduct`;

    const descriptions: { [key: string]: string } = {};
    descriptions[item.language] = item.value;
    const body = {
      name: this.softwareProductName,
      description: descriptions
    };

    this.http.patch(path, body).pipe(finalize(() => this.requestSoftwareProductDetails())).subscribe({
      error: (error) => {
        this.errorMessages.push(error)
        throw error;
      },
      complete: () => {
        this.success = true;
      }
    });
  }

  public remove(item: TranslatedString): void {}

  public assignValues(target: SoftwareProduct, source: SoftwareProduct): void {
    Object.assign(target, source);
  }

  public showMessages(){
    if(this.errorMessages.length > 0){
      this.errorMessages.forEach(error => {
        this.toastService.ShowError(error, false);
      });
    } else if(this.success) {
      const message = this.softwareProductName + this.translateService.instant('common.toastMessages.editSuccess');
      this.toastService.ShowSuccess(message, false);
    }
    this.errorMessages = [];
    this.success = false;
  }
}

