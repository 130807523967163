import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  AddEvent,
  EditEvent,
  GridComponent,
  SaveEvent
} from '@progress/kendo-angular-grid';
import { SoftwareProduct } from 'src/app/models/software-product';
import { TranslatedString } from 'src/app/models/translated-string.model';
import ISO6391 from 'iso-639-1';
import { SoftwareProductsDetailsService } from './software-products-details.service';
import { FilterService } from 'src/app/shared/filter/filter.service';
import { GridService } from 'src/app/shared/grid/grid.service';

@Component({
  selector: 'app-software-products-details',
  templateUrl: './software-products-details.component.html',
  styleUrls: ['./software-products-details.component.scss'],
  providers: [FilterService, GridService]
})
export class SoftwareProductsDetailsComponent implements OnDestroy {
  private sw: SoftwareProduct;

  @Input() get softwareProduct(): SoftwareProduct {
    return this.sw;
  }

  set softwareProduct(value: SoftwareProduct) {
    this.sw = value;
    this.softwareProductsDetailsService.setSoftwareProduct(
      this.softwareProduct.name
    );
    this.prepareData();
  }

  public showEditPopup: boolean = false;

  @ViewChild('grid') grid: GridComponent;

  public editedRowIndex: number;

  public formGroup: FormGroup;

  public dataItem: TranslatedString;

  public isNew: boolean;

  public languages = ISO6391.getLanguages(ISO6391.getAllCodes());

  constructor(
    public readonly softwareProductsDetailsService: SoftwareProductsDetailsService,
    private readonly formBuilder: FormBuilder,
    public readonly filterService: FilterService,
    public readonly gridService: GridService
  ) {
    this.createFormGroup = this.createFormGroup.bind(this);
    this.softwareProductsDetailsService.getSoftwareProductDetails().subscribe({
      next: (next) => {
        this.sw = next;
        this.prepareData();
      }
    });
  }

  ngOnInit(): void {
    let fields = ['languageName', 'value'];
    this.filterService.setFields(fields);
    this.filterService.gridFilter.subscribe((next) => {
      this.gridService.filterChanged(next);
    })
    this.gridService.state.take= 5
    this.gridService.init(this.grid);    
  }

  ngOnDestroy(): void {
    this.softwareProductsDetailsService.showMessages();
  }

  private prepareData() {
    const langs = new Array<TranslatedString>();
    if (this.softwareProduct.description !== null) {
      const descriptions = Array.from(
        Object.keys(this.softwareProduct.description)
      );
      // eslint-disable-next-line no-restricted-syntax
      descriptions.forEach((key) => {
        langs.push({
          language: key,
          value: this.softwareProduct.description[key],
          languageName: ISO6391.getName(key)
        });
      });
    }
    this.ensureNewItemIsOnTop(langs);
    this.gridService.data = langs
    this.gridService.processView();
  }

  private ensureNewItemIsOnTop(data: Array<TranslatedString>){
    if(this.dataItem != null && this.isNew){
      if(this.gridService.state.sort == null){
        this.gridService.state.sort = [];
      }
      this.gridService.state.sort.unshift({
        field: 'isNew',
        dir: 'desc'
      });
      const index = data.findIndex(x => x.language == this.dataItem.language)
      if(index >= 0){
        data[index]['isNew'] = 1;
        this.isNew = false;
      }      
    }
  }

  public createFormGroup(args: any): FormGroup {
    const item = args.isNew ? {} : args.dataItem;
    const translatedString = item as TranslatedString;

    this.formGroup = this.formBuilder.group({
      language: translatedString.language,
      value: translatedString.value
    });

    return this.formGroup;
  }

  public addButtonClicked(): void {
    this.isNew = true;
    this.editedRowIndex = undefined;
    this.dataItem = {} as TranslatedString;

    const event: AddEvent = {
      dataItem: this.dataItem,
      isNew: this.isNew,
      rowIndex: this.editedRowIndex,
      sender: this.grid
    };

    this.grid.add.emit(event);
    this.showEditPopup = true;
  }

  public showPopup() {
    this.showEditPopup = true;
  }

  public savePopup() {
    if(!this.formGroup.valid){
      this.formGroup.markAllAsTouched();
      return;
    };

    const event: SaveEvent = {
      formGroup: this.formGroup,
      rowIndex: this.editedRowIndex,
      isNew: this.isNew,
      dataItem: this.dataItem,
      sender: this.grid
    };

    this.grid.save.emit(event);
    this.closePopup();
  }

  public closePopup() {
    if (this.isNew) {
      this.grid.closeRow();
    } else {
      this.grid.closeRow(this.editedRowIndex);
    }
    this.showEditPopup = false;
  }

  public editHandler(event: EditEvent) {
    this.grid = event.sender;
    this.dataItem = event.dataItem;
    this.isNew = event.isNew;
    this.editedRowIndex = event.rowIndex;
    this.showEditPopup = true;
  }

  public handleFilter(value) {
    if (value) {
      this.languages = ISO6391.getLanguages(ISO6391.getAllCodes()).filter((x) =>
        x.name.toLowerCase().startsWith(value.toLowerCase())
      );
    } else {
      this.languages = ISO6391.getLanguages(ISO6391.getAllCodes());
    }
  }
}
