import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BsusResult } from '../models/bsus-result.model';
import { Software } from '../models/software.model';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  constructor(private readonly http: HttpClient) {}

  public initateDownload(softwareId: string){
    this.getSoftwareDownloadURL(softwareId).subscribe(
      {next: (next) => window.location.assign(next)}
    );
  }

  public getSoftwareDownloadURL(softwareId: string): Observable<string> {
    const path = `${environment.backend}update/download?id=${softwareId}&equipment=99886633`;

    return this.http.get<BsusResult<Software>>(path).pipe(
      map((next) => next.result[0].downloadUrl)
    );
  }
}
