<div class="container">
  <div class="head">
    <app-header></app-header>
  </div>
  <div class="content-wrapper">
    <div class="inner-content-wrapper">
      <div class="breadcrumb">
        <app-breadcrumb></app-breadcrumb>
      </div>
      <div class="content">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>