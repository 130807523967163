<div class="header">

    <div class="header-central">
        <div class="header-logo">
            <a href="/"><img src="/assets/images/logo-white.svg"/></a>
        </div>
        <div class="app-info">
            <div class="appName">
                <img style="width:20rem; height:1.5rem;" src="/assets/images/bsh-logo.svg"/>
            </div>
            <div class="show-username" *ngIf="username">
                <svg class="icon"><use xlink:href="/assets/icons/symbol-defs.svg#icon-icons-person-outline"></use></svg>
                {{username}}
            </div>
            <div class="language-select" *ngIf="!showSelectLanguage" (click)="selectLanguage()">
                <svg class="icon"><use xlink:href="/assets/icons/symbol-defs.svg#icon-icons-language"></use></svg>
                {{userLanguage | uppercase}}
            </div>
            <div class="language-select-active" *ngIf="showSelectLanguage" (click)="closeSelectLanguage()">
                <svg class="icon"><use xlink:href="/assets/icons/symbol-defs.svg#icon-icons-language"></use></svg>
                {{userLanguage | uppercase}}
                <div class="content-overlay"></div>
                <div class="header-overlay"></div>
                <div class="language-switch">
                    <app-language-switch></app-language-switch>
                </div>
            </div>
            <div class="signIn" *ngIf="!username" (click)="signIn()" translate>
                <svg class="icon"><use xlink:href="/assets/icons/symbol-defs.svg#icon-icons-login"></use></svg>
                common.signIn
            </div>
            <div class="signOut" *ngIf="username" (click)="signOut()" translate>
                <svg class="icon"><use xlink:href="/assets/icons/symbol-defs.svg#icon-icons-logout"></use></svg>
                common.signOut
            </div>
        </div>
    </div>

    <div class="navigation">
        <nav>
        <a routerLink="/overview" routerLinkActive="routerLinkActive" translate>navigation.releases</a>
        <a routerLink="/softwareProducts" *ngIf="userService.hasAnyRole([roles.SoftwareProductRead, roles.SoftwareProductWrite]) | async" routerLinkActive="routerLinkActive" translate>navigation.softwareProducts</a>
        <a routerLink="/platforms" *ngIf="userService.hasAnyRole([roles.PlatformRead, roles.PlatformWrite]) | async" routerLinkActive="routerLinkActive" translate>navigation.platforms</a>
        <a routerLink="/settings" routerLinkActive="routerLinkActive" translate>navigation.settings</a>
        </nav>
        <div class="nav-search">
            <app-searchbar></app-searchbar>
        </div>
    </div>
</div>