import { Component, Input, ViewChild } from '@angular/core';
import { Software } from 'src/app/models/software.model';
import {
  AddEvent,
  EditEvent,
  GridComponent,
  SaveEvent
} from '@progress/kendo-angular-grid';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { UserService } from 'src/app/shared/user/user.service';
import { User } from 'src/app/models/user.model';
import { ActivatedRoute } from '@angular/router';
import { LocaleService } from 'src/app/shared/translation/locale.service';
import { PlatformService } from 'src/app/shared/platform/platform.service';
import { FilterService } from 'src/app/shared/filter/filter.service';
import { Platform } from 'src/app/models/platform.model';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { DetailService } from './detail.service';
import { WarningService } from 'src/app/shared/warning/warning.service';
import { Warning } from 'src/app/models/warning.model';
import { Country } from 'src/app/models/country.model';
declare var require: any;
var isocountries = require('i18n-iso-countries');
import ISO6391 from 'iso-639-1';
import { Language } from 'src/app/models/language.model';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { DownloadService } from 'src/app/shared/download.service';
import { SoftwareProductService } from 'src/app/shared/software-product.service';
import { SoftwareProduct } from 'src/app/models/software-product';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { GridService } from 'src/app/shared/grid/grid.service';
import { Roles } from 'src/app/models/roles.enum';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
  providers: [FilterService, GridService]
})
export class DetailComponent {
  
  private static readonly millisPerDay = 86400000;

  public packageTypes = ['FULL', 'PATCH', 'HOTFIX', 'LANGUAGEPACK'];

  public releaseTypes = ['RELEASE', 'RC', 'BETA', 'ALPHA']

  public softwareProducts: SoftwareProduct[];

  public countryList: Country[] = [];

  public languageList: Language[] = ISO6391.getLanguages(ISO6391.getAllCodes());

  public showEditPopup: boolean = false;

  public showReleaseNotesPopup: boolean = false;

  public releaseNotesSoftware: Software = null;

  public descriptionFormControl;

  public animate: {
    type: 'zoom';
    duration: 200;
  };

  public editedRowIndex: number;

  public isNew: boolean;

  public dataItem;

  public formGroup: FormGroup;

  public itemToRemove: Software;

  public removeConfirmationSubject: Subject<boolean> = new Subject<boolean>();

  @ViewChild('grid') grid: GridComponent;

  public user: User;

  private defaultNewDays: number = 5;

  public platforms: Platform[];

  public warnings: Warning[];

  public selectedPlatforms: string[] = [];

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };

  public fileRestrictions: FileRestrictions = {
    allowedExtensions: [".zip"],
  };

  constructor(
    public readonly detailService: DetailService,
    private readonly userService: UserService,
    private readonly formBuilder: FormBuilder,
    private readonly route: ActivatedRoute,
    private readonly localeService: LocaleService,
    private readonly translateService: TranslateService,
    private readonly softwareProductService: SoftwareProductService,
    private readonly platformService: PlatformService,
    private readonly warningService: WarningService,
    private readonly downloadService: DownloadService,
    public readonly filterService: FilterService,
    public readonly gridService: GridService
  ) {
    this.loadUser();
    this.loadPlatforms();
    this.loadWarnings();
    this.readQueryParams();
    this.prepareCountries();
    translateService.onLangChange.subscribe(() => this.prepareCountries());
    this.createFormGroup = this.createFormGroup.bind(this);
    this.removeConfirmation = this.removeConfirmation.bind(this);
  }

  ngOnInit(): void {
    let fields = ['name', 'displayName', 'version', 'releaseType', 'packageType', 'dateString',
    'releaseDateString', 'revokeDateString', 'countries', 'languages', 'platformSearch'];
    this.filterService.setFields(fields);
    this.gridService.state.sort = [{field: 'created', dir: 'desc'}];
    this.filterService.gridFilter.subscribe({
      next: (next) => {
        this.gridService.filterChanged(next);
      }
    })
    this.gridService.init(this.grid);
  }

  private loadUser(): void {
    this.userService.GetUser().subscribe({
      next: (next) => {
        this.user = next;
      }
    });
  }

  private loadPlatforms(): void {
    this.platformService.GetPlatforms().subscribe({
      next: (next) => {
        this.platforms = next;
      }
    });
  }

  private loadWarnings(): void {
    this.warningService.GetWarnings().subscribe({
      next: (next) => {
        this.warnings = next;
      }
    })
  }

  private loadSoftwareProducts(): void {
    this.softwareProductService.GetSoftwareProducts().subscribe({
      next: (next) => {
        this.softwareProducts = next
      }
    })
  }

  private readQueryParams(): void {
    this.route.queryParamMap.subscribe((next) => {
      if(next.has('searchString')){
        this.searchForSoftware(next.get('searchString'))
      } else {
        this.loadSoftwareList(next.get('software'));
      }
    });
  }

  private prepareCountries(): void {
    let lang = this.localeService.getUserLanguage();
    if(lang == 'de'){
      isocountries.registerLocale(require("i18n-iso-countries/langs/de.json"));
    } else {
      lang == 'en'
      isocountries.registerLocale(require("i18n-iso-countries/langs/en.json"));
    }
    
    let list = isocountries.getNames(lang, {select:"official"});
    this.countryList = [];
    for(let code in list){
      let country: Country ={
        twoLetterCode: code.toLowerCase(),
        name: list[code]
      }
      this.countryList.push(country)
    }
  }

  private loadSoftwareList(softwareName: string): void {
    this.detailService.getSoftwareDetails(softwareName).subscribe({
      next: (next) => {
        this.processSoftware(next);
      }
    });
  }

  private searchForSoftware(searchString: string): void {
    this.detailService.searchSoftwareDetails(searchString).subscribe({
      next: (next) => {
        this.processSoftware(next);
      }
    });
  }

  processSoftware(softwareList: Software[]): void {
    this.addDateStrings(softwareList);
    this.gridService.data = softwareList;
    this.gridService.processView();
  }

  private addDateStrings(softwareList: Software[]): void {
    const locale = this.localeService.getUserLocale();
    softwareList.forEach((element) => {
      element.dateString = element.date.toLocaleString(locale);
      element.releaseDateString = element.releaseDate?.toLocaleString(locale);
      element.revokeDateString = element.revokeDate?.toLocaleString(locale);
    });
  }

  public createFormGroup(args: any): FormGroup {
    const item = args.isNew ? {} : args.dataItem;
    const sw = item as Software;

    if(!sw.countries){
      sw.countries = []
    }


    if(!sw.languages){
      sw.languages = []
    }

    if(!sw.platforms){
      sw.platforms = []
    }

    this.formGroup = this.formBuilder.group({
      name: sw.name,
      displayName: sw.displayName,
      version: sw.version,
      releaseType: sw.releaseType,
      downloadUrl: sw.downloadUrl,
      description: sw.description,
      noAuthentication: sw.noAuthentication,
      released: sw.released,
      releaseDate: sw.releaseDate,
      revokeDate: sw.revokeDate,
      packageType: sw.packageType,
      warningId: sw.warningId,
    });

    this.formGroup.addControl("countries", new FormControl(sw.countries));
    this.formGroup.addControl("languages", new FormControl(sw.languages));
    this.formGroup.addControl("platforms", new FormControl(sw.platforms));

    return this.formGroup;
  }

  public addButtonClicked(): void {
    this.isNew = true;
    this.editedRowIndex = undefined;
    this.dataItem = {} as Software;

    this.selectedPlatforms = [];
    this.loadSoftwareProducts();

    const event: AddEvent = {
      dataItem: this.dataItem,
      isNew: this.isNew,
      rowIndex: this.editedRowIndex,
      sender: this.grid
    };

    this.grid.add.emit(event);
    this.showEditPopup = true;
  }

  public savePopup() {
    if(!this.formGroup.valid){
      this.formGroup.markAllAsTouched();
      return;
    };

    if(!this.isNew && this.selectedPlatforms.length <= 0){
      return;
    }

    const event: SaveEvent = {
      formGroup: this.formGroup,
      rowIndex: this.editedRowIndex,
      isNew: this.isNew,
      dataItem: this.dataItem,
      sender: this.grid
    };

    this.grid.save.emit(event);

    if(!this.isNew){
      this.dataItem.platforms = this.selectedPlatforms;

      this.detailService.setPlatformsForSoftware(
        this.dataItem,
        this.selectedPlatforms
      );
    }
    
    this.closePopup();
  }

  public closePopup() {
    if (this.isNew) {
      this.grid.closeRow();
    } else {
      this.grid.closeRow(this.editedRowIndex);
    }
    this.showEditPopup = false;
  }

  public markAsNew(date: Date): boolean {
    let days = this.user?.showNewDays;
    if (days == null || days === 0) {
      days = this.defaultNewDays;
    }
    const diffInMillis = Date.now() - date.getTime();

    if (diffInMillis < days * DetailComponent.millisPerDay) {
      return true;
    }
    return false;
  }

  public showReleaseNotes(event: Event, software: Software) {
    this.showReleaseNotesPopup = true;
    this.releaseNotesSoftware = software;
  }

  public closeReleaseNotesPopup() {
    this.showReleaseNotesPopup = false;
  }

  public removeConfirmation(dataItem: Software): Subject<boolean> {
    this.itemToRemove = dataItem;
    return this.removeConfirmationSubject;
  }

  public confirmRemove(shouldRemove: boolean): void {
    this.removeConfirmationSubject.next(shouldRemove);
    this.itemToRemove = null;
  }

  public editHandler(event: EditEvent) {
    this.dataItem = event.dataItem;
    this.isNew = event.isNew;
    this.editedRowIndex = event.rowIndex;
    this.showEditPopup = true;
    this.detailService.getPlatformsForSoftware(this.dataItem).subscribe({
      next: (next) => {
        const platforms = next.map((x) => x.name);
        this.selectedPlatforms = platforms;
      }
    });
  }

  public isReleased(dataItem: Software): boolean {  
    const date = new Date();
    
    if(dataItem.released){
      if(!dataItem.releaseDate || dataItem.releaseDate <= date){
        if(!dataItem.revokeDate || dataItem.revokeDate > date){
          return true;
        }
      }
    }
    return false;
  }

  public initiateDownload(event: Event, software: Software){
    this.downloadService.initateDownload(software.guid);
  }

  public canEditSoftware(): Observable<boolean> {
    return this.userService.hasAnyRole([Roles.SoftwareWrite, Roles.SoftwareAdmin]);
  }
}
