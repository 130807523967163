import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FilterService } from './filter.service';
import { TranslateService } from '@ngx-translate/core';
import { Filter } from 'src/app/models/filter.model';
import {
  CompositeFilterDescriptor,
  DataResult,
  isCompositeFilterDescriptor,
  process,
  State
} from '@progress/kendo-data-query';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

  private _searchString: string = '';
  get searchString(): string {
    return this._searchString;
  }
  @Input() set searchString(value: string) {
    this.searchStringChanged(value);
  }

  @Input() fields: string[];
  @Input() data: any[];

  @Output() filterChanged = new EventEmitter<CompositeFilterDescriptor>();

  public filter: Filter;

  constructor(public readonly translateService: TranslateService){}

  ngOnInit(): void {
    this.resetFilter()
  }

  public searchStringChanged(value: string): void {  
    value = value ?? '';
    if (value == '' && this._searchString !== '') {
      this._searchString = value;
      this.resetFilter();
      this.filterChanged.emit();
    }
    else {
      this._searchString = value;
      this.processFilter();
    }
  }

  public filterElements(filter: string): void {
    if(this._searchString === ''){
      return;
    }
    
    let newFilter = this.GetFilter(filter);
    this.filterChanged.emit(newFilter); 
  }

  private resetFilter(): void {
    this.filter = {
      contains: 0,
      notContains: 0,
      startsWith: 0,
      endsWith: 0,
      isSame: 0,
      notSame: 0
    };
  }

  private processFilter(): void {
    if(this.data == null){
      this.resetFilter();
      return;
    }
    
    let state: State = {
      filter: this.GetFilter('eq')
    };
    
    let result = process(this.data, state);
    this.filter.isSame = result.data.length;

    this.filter.notSame = this.data.length - this.filter.isSame;

    state = {
      filter: this.GetFilter('startswith')
    };
    result = process(this.data, state);
    this.filter.startsWith = result.data.length;

    state = {
      filter: this.GetFilter('endswith')
    };
    result = process(this.data, state);
    this.filter.endsWith = result.data.length;

    state = {
      filter: this.GetFilter('contains')
    };
    result = process(this.data, state);
    this.filter.contains = result.data.length;

    this.filter.notContains = this.data.length - this.filter.contains;
  }

  private GetFilter(comparator: string): CompositeFilterDescriptor {
    let logic: 'or' | 'and' = 'or';
    if (comparator === 'doesnotcontain' || comparator === 'neq') {
      logic = 'and';
    }

    const filter: CompositeFilterDescriptor = {
      logic,
      filters: []
    };

    this.fields.forEach(fieldName => {
      filter.filters.push({
        operator: comparator,
        value: this._searchString,
        field: fieldName
      });  
    });

    return filter;
  }
}
