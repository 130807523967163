import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DetailComponent } from './routes/overview/detail/detail.component';
import { OverviewComponent } from './routes/overview/overview.component';
import { PlatformsComponent } from './routes/platforms/platforms.component';
import { SettingsComponent } from './routes/settings/settings.component';
import { SoftwareProductsComponent } from './routes/software-products/software-products.component';
import { AuthGuardService } from './shared/auth/auth-guard.service';

const routes: Routes = [
  { path: '', redirectTo: '/overview', pathMatch: 'full' },
  { path: 'overview', component: OverviewComponent, pathMatch: 'full' },
  { path: 'overview/details', component: DetailComponent },
  { path: 'settings', component: SettingsComponent },
  { path: 'platforms', component: PlatformsComponent, canActivate: [AuthGuardService] },
  { path: 'softwareProducts', component: SoftwareProductsComponent, canActivate: [AuthGuardService] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  providers: [AuthGuardService]
})
export class AppRoutingModule {}
