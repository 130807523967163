import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastService } from '../toast/toast.service';
import { de } from 'src/locale/de';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private readonly toast: ToastService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof ErrorEvent) {
          // client-side error
          this.toast.ShowError('shared.interceptors.clientError');
          return throwError(() => error.error.message);
        } else {
          // server-side error
          this.toast.ShowError('shared.interceptors.serverError', true, {statusCode: error.status});
          switch(error.status) {
          case 401: 
            return throwError(() => error.statusText);
          case 403:
            return throwError(() => error.error);
          default:
            return throwError(()=> "Reponse object: " + JSON.stringify(error.error));
          }          
        }        
      })
    );
  }
}
