import { kendo } from './en-US.kendo';

export const en = {
  kendo,
  common: {
    language: 'Language',
    remove: 'Delete',
    next: 'Next',
    add: 'Add',
    or: 'or',
    loading: 'loading...',
    selectLanguage: 'Select Language:',
    search: 'Search',
    signIn: 'Sign in',
    signOut: 'Sign Out',
    edit: 'Edit',
    save: 'Save',
    cancel: 'Cancel',
    close: 'Close',
    requiredFields: 'Required',
    user: 'User',
    download: 'Download',
    filter: {
      startsWith: 'starts with',
      endsWith: 'ends with',
      notSame: 'not the same',
      isSame: 'the same',
      contains: 'contains',
      notContains: 'not contains'
    },
    documents: 'Documents',
    yes: 'Yes',
    no: 'No',
    toastMessages: {
      editSuccess:' was changed successfully',
      addSuccess: ' was added successfully',
      removeSuccess: ' was deleted successfully'
    },
    removeConfirmation: {
      message: 'Are you sure want to delete the following item:',
      title: 'Remove Item'
    }
  },
  navigation: {
    releases: 'Releases',
    settings: 'Settings',
    platforms: 'Platforms',
    softwareProducts: 'Software Products'
  },
  shared: {
    interceptors: {
      serverError: 'Server responded with error {{statusCode}}',
      clientError: 'Client error occurred'
    },
    auth: {
      multipleAccounts: 'Multiple Accounts detected',
      notAuthenticated: 'No user signed in'
    }
  },
  overview: {
    select_software: 'Select software',
    details: {
      displayName: 'Display Name',
      version: 'Version',
      createDate: 'Created',
      availableFrom: 'Available From',
      availableUntil: 'Available Until',
      noAuthentication: 'License Free Software',
      description: 'Description',
      edit: 'Edit release',
      add: 'Add release',
      packageType: 'Package type',
      releaseType: 'Release type',
      released: 'Released',
      platforms: 'Platforms',
      languages: 'Languages',
      countries: 'Countries',
      documents: 'Documents',
      size: 'Size',
      createdBy: 'Created by',
      created: 'Created',
      modifiedBy: 'Modified by',
      modified:'Modified',
      warning:'Warning/Remarks'
    },
    releasenotes: {
      add: 'Add document',
      created: 'Created',
      language: 'Language',
      fileExtension: 'File Extension',
      fileName: 'File Name',
      selectLanguage: 'Select Language:',
      selectFile: 'Select File:',
      selectType: 'Select Document Type:',
      view: 'View',
      overwriteWarning: '!!!ATTENTION!!! There already exists a document for this language. If you perform an upload, the existing document will be overwritten!'
    }
  },
  platform: {
    name: 'Name',
    description: 'Description',
    modified: 'Modified',
    modifiedBy: 'Modified by',
    created: 'Created',
    createdBy: 'Created by',
    add: 'Add platform',
    edit: 'Edit platform',
    productHierarchyId: "Product Hierarchy Id",
    productHierarchyIdPlaceholder: "SAP Product Hierarchy Id"
  },
  softwareProduct: {
    language: 'Language',
    category: 'Category',
    packageinfoId: 'PackageinfoId',
    description: 'Description',
    add: 'Add software product',
    edit: 'Edit software product',
    details: {
      language: 'Language',
      description: 'Description',
      add: 'Add Language',
      edit: 'Edit software description'
    }
  },
  settings: {
    daysShowNew: 'Show that a software release is new for X days after upload:',
    platforms: 'Inform me about new software for the following platforms:'
  }
};
