import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  AddEvent,
  EditEvent,
  GridComponent,
  SaveEvent
} from '@progress/kendo-angular-grid';
import { Subject } from 'rxjs';
import { SoftwareProduct } from 'src/app/models/software-product';
import { LocaleService } from 'src/app/shared/translation/locale.service';
import { UserService } from 'src/app/shared/user/user.service';
import { FilterService } from 'src/app/shared/filter/filter.service';
import { SoftwareProductsService } from './software-products.service';
import { GridService } from 'src/app/shared/grid/grid.service';
import { Roles } from 'src/app/models/roles.enum';
import { Category } from 'src/app/models/product-category.enum';

@Component({
  selector: 'app-software-products',
  templateUrl: './software-products.component.html',
  styleUrls: ['./software-products.component.scss'],
  providers: [FilterService, GridService]
})
export class SoftwareProductsComponent implements OnInit {
  
  public category = Category;
  public categories = Object.values(Category);
  
  public showEditPopup: boolean = false;

  public userLanguage: string;

  @ViewChild('grid') grid: GridComponent;

  public editedRowIndex: number;

  public formGroup: FormGroup;

  public dataItem: SoftwareProduct;

  public isNew: boolean;

  public itemToRemove: SoftwareProduct;

  public removeConfirmationSubject: Subject<boolean> = new Subject<boolean>();

  public roles = Roles;

  public loading: boolean = false;

  constructor(
    public readonly softwareProductsService: SoftwareProductsService,
    private readonly translateService: TranslateService,
    private readonly localeService: LocaleService,
    private readonly formBuilder: FormBuilder,
    public readonly userService: UserService,
    public readonly filterService: FilterService,
    public readonly gridService: GridService
  ) {
    this.createFormGroup = this.createFormGroup.bind(this);
    this.removeConfirmation = this.removeConfirmation.bind(this);
  }

  public ngOnInit(): void {
    this.loadData();
    this.userLanguage = this.localeService.getUserLanguage();
    this.translateService.onLangChange.subscribe({
      next: () => {
        this.userLanguage = this.localeService.getUserLanguage();
      }
    });
    let fields = ['name', 'packageinfoId'];
    this.filterService.setFields(fields);
    this.filterService.gridFilter.subscribe(next => {
      this.gridService.filterChanged(next);
    });
    this.gridService.init(this.grid);
  }

  private loadData(): void {
    this.softwareProductsService.getSoftwareProducts().subscribe({
      next: (next) => {
        this.processData(next);
      }
    });
  }

  private processData(data: SoftwareProduct[]): void {
    this.ensureNewItemIsOnTop(data);
    this.gridService.data = data;
    this.gridService.processView()
  }

  private ensureNewItemIsOnTop(data: SoftwareProduct[]){
    if(this.dataItem != null && this.isNew){
      if(this.gridService.state.sort == null){
        this.gridService.state.sort = [];
      }
      this.gridService.state.sort.unshift({
        field: 'isNew',
        dir: 'desc'
      });
      const index = data.findIndex(x => x.name == this.dataItem.name)
      if(index >= 0){
        data[index]['isNew'] = 1;
        this.isNew = false;
      }
    }
  }

  public createFormGroup(args: any): FormGroup {
    const item = args.isNew ? {} : args.dataItem;
    const softwareProduct = item as SoftwareProduct;

    this.formGroup = this.formBuilder.group({
      name: softwareProduct.name,
      description: softwareProduct.description,
      category: Category[softwareProduct.category],
      packageinfoId: softwareProduct.packageinfoId
    });

    return this.formGroup;
  }

  public addButtonClicked(): void {
    this.isNew = true;
    this.editedRowIndex = undefined;
    this.dataItem = {} as SoftwareProduct;

    const event: AddEvent = {
      dataItem: this.dataItem,
      isNew: this.isNew,
      rowIndex: this.editedRowIndex,
      sender: this.grid
    };

    this.grid.add.emit(event);
    this.showPopup();
  }

  public showPopup() {
    this.showEditPopup = true;
  }

  public savePopup() {
    if(!this.formGroup.valid){
      this.formGroup.markAllAsTouched();
      return;
    };

    const event: SaveEvent = {
      formGroup: this.formGroup,
      rowIndex: this.editedRowIndex,
      isNew: this.isNew,
      dataItem: this.dataItem,
      sender: this.grid
    };

    this.grid.save.emit(event);
    this.closePopup();
  }

  public closePopup() {
    //this.delayedLoadData();
    if (this.isNew) {
      this.grid.closeRow();
    } else {
      this.grid.closeRow(this.editedRowIndex);
    }
    this.showEditPopup = false;
  }

  private delayedLoadData(): void {
    const _service = this.softwareProductsService;
    setTimeout(function () {
      _service.requestSoftwareProducts()
    }, 500);
  }

  public editHandler(event: EditEvent) {
    this.dataItem = event.dataItem;
    this.isNew = event.isNew;
    this.editedRowIndex = event.rowIndex;
    this.showPopup();
  }

  public removeConfirmation(dataItem: SoftwareProduct): Subject<boolean> {
    this.itemToRemove = dataItem;
    return this.removeConfirmationSubject;
  }

  public confirmRemove(shouldRemove: boolean): void {
    this.removeConfirmationSubject.next(shouldRemove);
    this.itemToRemove = null;
  }
}
