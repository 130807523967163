import { Component, Input } from '@angular/core';
import { ReleaseNote } from 'src/app/models/release-note.model';
import { DocumentType } from 'src/app/models/document-type.enum';
import ISO6391 from 'iso-639-1';
import { UserService } from 'src/app/shared/user/user.service';
import { FilterService } from 'src/app/shared/filter/filter.service';
import { ReleaseNotesService } from './release-notes.service';
import { SuccessEvent } from '@progress/kendo-angular-upload';
import { Subject } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GridService } from 'src/app/shared/grid/grid.service';
import { TrackedItem } from 'src/app/models/tracked-item.model';
import { Roles } from 'src/app/models/roles.enum';

@Component({
  selector: 'app-release-notes',
  templateUrl: './release-notes.component.html',
  styleUrls: ['./release-notes.component.scss'],
  providers: [FilterService, GridService]
})
export class ReleaseNotesComponent {
  private swguid: string;

  @Input() get guid(): string {
    return this.swguid;
  }

  set guid(value: string) {
    this.swguid = value;
    this.getData();
  }

  public showAddReleaseNote: boolean = false;

  public uploadTargetUrl: string = null;

  public uploadTargetLanguage: string = null;

  public uploadTargetType: string = null;

  public successfulUpload: boolean = false;

  public itemToRemove: ReleaseNote;

  public removeConfirmationSubject: Subject<boolean> = new Subject<boolean>();

  public formGroup: FormGroup;

  public languageNames = ISO6391.getAllNames();

  public documentTypes = Object.values(DocumentType)

  public loading: boolean = false;

  public roles = Roles;

  constructor(
    public readonly releaseNotesService: ReleaseNotesService,
    private readonly formBuilder: FormBuilder,
    public readonly userService: UserService,
    public readonly filterService: FilterService,
    public readonly gridService: GridService
  ) {
    this.createFormGroup = this.createFormGroup.bind(this);
    this.removeConfirmation = this.removeConfirmation.bind(this);
  }

  ngOnInit(): void {
    let fields = ['languageName', 'fileExtension', 'fileName', 'type'];
    this.filterService.setFields(fields);
    this.filterService.gridFilter.subscribe((next) => {
      this.gridService.filterChanged(next)
    });
    this.gridService.state.take = 8;
    this.releaseNotesService.subject.subscribe({
      next: (next) => {
        this.processData(next);
      }
    });
  }

  private getData(renewCache: boolean = false) {
    this.loading = true;
    this.releaseNotesService.get(this.swguid, renewCache)
  } 

  private processData(data: ReleaseNote[]): void {
    this.addLanguageNames(data);
    this.ensureNewItemIsOnTop(data);
    this.gridService.data = data;
    this.gridService.processView();
  }

  private addLanguageNames(data: ReleaseNote[]) {
    data.forEach((element) => {
      element.languageName = this.getLanguage(element.language);
    });
  }

  private ensureNewItemIsOnTop(data: ReleaseNote[]){
    if(this.successfulUpload){
      if(this.gridService.state.sort == null){
        this.gridService.state.sort = [];
      }
      this.gridService.state.sort.unshift({
        field: 'isNew',
        dir: 'desc'
      });
      const index = this.getIndexOfNewestItem(data);
      data[index]['isNew'] = 1;
      this.successfulUpload = false;
    }
  }

  public getIndexOfNewestItem(data: TrackedItem[]): number {
    let index = 0;
    for(let i = 0; i < data.length; i++){
      if(data[i].created > data[index].created){
        index = i;
      }
    }
    return index;
  }

  public createFormGroup(args: any): FormGroup {
    const item = args.isNew ? {} : args.dataItem;
    const rn = item as ReleaseNote;

    this.formGroup = this.formBuilder.group({
    });

    return this.formGroup
  }

  public showReleaseNote(event: Event, releaseNote: ReleaseNote) {
    const link = this.releaseNotesService.getDocumentContentLink(releaseNote.id);
    window.open(link, '_blank');
  }

  public addReleaseNote(event: Event) {
    this.showAddReleaseNote = true;
  }

  public closePopup() {
    this.showAddReleaseNote = false;
    if(this.successfulUpload){
      this.getData(true);
    }
  }

  public prepareUpload() {
    if(this.uploadTargetLanguage == null || this.uploadTargetType == null || this.swguid == null)
    {
      return;
    }

    this.uploadTargetUrl = this.releaseNotesService.getDocumentUploadLink(
      this.swguid,
      this.uploadTargetLanguage,
      this.uploadTargetType
    );
  }

  public targetLanguageChanged(value: string) {
    this.uploadTargetLanguage = ISO6391.getCode(value);
    this.prepareUpload();
  }

  public targetTypeChanged(value: string) {
    this.uploadTargetType = value;
    this.prepareUpload();
  }

  public uploadSuccess(event: SuccessEvent){
    this.successfulUpload = true;
    this.uploadTargetLanguage = null;
    this.uploadTargetType = null;
  }

  public getLanguage(value: string): string {
    return ISO6391.getName(value);
  }

  public handleFilter(value) {
    if (value) {
      this.languageNames = ISO6391.getAllNames().filter((x) =>
        x.toLowerCase().startsWith(value.toLowerCase())
      );
    } else {
      this.languageNames = ISO6391.getAllNames();
    }
  }

  public removeConfirmation(dataItem: ReleaseNote): Subject<boolean> {
    this.itemToRemove = dataItem;
    return this.removeConfirmationSubject;
  }

  public confirmRemove(shouldRemove: boolean): void {
    this.removeConfirmationSubject.next(shouldRemove);
    this.itemToRemove = null;
  }

}
