<div class="k-overlay" *ngIf="showEditPopup"></div>
<kendo-window *ngIf="showEditPopup"
    [top]= "isNew ? undefined : 20"
    [minWidth]="600"
    [title]="(isNew ? 'softwareProduct.add' : 'softwareProduct.edit') | translate"
    (close)=closePopup()>
    <div class="(isNew ? popupContent : popupEditContent)">
        <form novalidate class="k-form" [formGroup]="formGroup">          
            <kendo-formfield *ngIf="isNew">
                <kendo-floatinglabel text="{{'softwareProduct.name' | translate}}*">
                    <input kendoTextBox formControlName="name" required/>
                </kendo-floatinglabel>
            </kendo-formfield>
            <kendo-formfield>
                <kendo-label [for]="categorySelect" text="{{'softwareProduct.category' | translate}}*"></kendo-label>
                <kendo-dropdownlist #categorySelect 
                formControlName="category"
                [data]="categories"
                required
            ></kendo-dropdownlist>
            </kendo-formfield>
            <kendo-formfield>
                <kendo-floatinglabel text="{{'softwareProduct.packageinfoId' | translate}}">
                    <input kendoTextBox [readonly]="!!dataItem.packageinfoId" formControlName="packageinfoId"/>
                </kendo-floatinglabel>
            </kendo-formfield>
            <div *ngIf=!isNew class="description">
                {{'softwareProduct.description' | translate }}{{':'}}
                <app-software-products-details #editDescription [softwareProduct]="dataItem"></app-software-products-details>
            </div>    
            <br>* {{'common.requiredFields' | translate}}
        </form>
    </div>
    <div class="popup-buttons">
        <button kendoButton (click)="savePopup()">{{"common.save" | translate}}</button>
        <button kendoButton (click)="closePopup()">{{"common.close" | translate}}</button>
    </div>
</kendo-window>

<kendo-dialog
      [title]="'common.removeConfirmation.title' | translate"
      *ngIf="itemToRemove"
      (close)="confirmRemove(false)"
>   
    <p class="dialog-content">
        {{"common.removeConfirmation.message" | translate}} <strong>{{itemToRemove.name}}</strong>?
    </p>
    <kendo-dialog-actions>
        <button kendoButton (click)="confirmRemove(false)">{{"common.no" | translate}}</button>
        <button kendoButton (click)="confirmRemove(true)" primary="true">{{"common.yes" | translate}}</button>
    </kendo-dialog-actions>
</kendo-dialog>
<div class="search">
    <div class="strech">
        <kendo-floatinglabel [text]="'common.search' | translate">
            <kendo-textbox
                [style.width.rem] = "25"
                [clearButton]="true"
                [value]="filterService.searchString"
                (valueChange)="filterService.searchStringChanged(gridService.data, $event)"
            ></kendo-textbox>
        </kendo-floatinglabel>
    </div>
    <div *ngIf="userService.hasAnyRole([roles.SoftwareProductWrite]) | async" class="addButton">
        <button kendoButton (click)="addButtonClicked()">
            {{'softwareProduct.add' | translate}}
            <svg class="icon"><use xlink:href="/assets/icons/symbol-defs.svg#icon-icons-add"></use></svg>
        </button>
    </div>
</div>

<kendo-grid #grid
    [data]="gridService.view"
    [loading]="softwareProductsService.loading" 
    [resizable]="true" 
    [pageSize]="gridService.state.take" 
    [skip]="gridService.state.skip" 
    [sortable]="true" 
    [sort]="gridService.state.sort" 
    [pageable]="true" 
    [navigable]="true"
    [scrollable]="'none'"
    [kendoGridReactiveEditing]="createFormGroup"
    [editService]="softwareProductsService"
    [removeConfirmation]="removeConfirmation"
    (edit)="editHandler($event)"
    (dataStateChange)="gridService.dataStateChange($event)">
    <ng-template kendoGridToolbarTemplate>
        <div class="filter" [ngClass]="{'selectableFilters': filterService.searchString}">
            <span (click)="filterService.filterElements('contains')">{{'common.filter.contains' | translate}}({{filterService.filter.contains}})</span>
            <span (click)="filterService.filterElements('doesnotcontain')">{{'common.filter.notContains' | translate}}({{filterService.filter.notContains}})</span>
            <span (click)="filterService.filterElements('eq')">{{'common.filter.isSame' | translate}}({{filterService.filter.isSame}})</span>
            <span (click)="filterService.filterElements('neq')">{{'common.filter.notSame' | translate}}({{filterService.filter.notSame}})</span>
            <span (click)="filterService.filterElements('startswith')">{{'common.filter.startsWith' | translate}}({{filterService.filter.startsWith}})</span>
            <span (click)="filterService.filterElements('endswith')">{{'common.filter.endsWith' | translate}}({{filterService.filter.endsWith}})</span>
        </div>
        <kendo-grid-column-chooser></kendo-grid-column-chooser>
    </ng-template>
    <kendo-grid-column title="{{'softwareProduct.name' | translate}}" field="name" [editable]="false" [width]="300"></kendo-grid-column>
    <kendo-grid-column title="{{'softwareProduct.category' | translate}}" field="category" [editable]="false" [width]="300">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{category[dataItem.category]}}</span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{'softwareProduct.packageinfoId' | translate}}" field="packageinfoId" [editable]="false" [width]="300"></kendo-grid-column>
    <kendo-grid-column title="{{'softwareProduct.description' | translate}}" field="description" [editable]="false">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{dataItem.description ? dataItem.description[userLanguage] : '' }}</span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-command-column *ngIf="userService.hasAnyRole([roles.SoftwareProductWrite]) | async" title="{{'common.edit' | translate}}" width="120">
        <ng-template kendoGridCellTemplate let-dataItem let-isNew>
            <div class="center">
                <button kendoGridEditCommand class="edit">
                    <svg class="icon"><use xlink:href="/assets/icons/symbol-defs.svg#icon-icons-edit"></use></svg>
                </button>
            </div>
        </ng-template>
    </kendo-grid-command-column>
    <kendo-grid-command-column *ngIf="userService.hasAnyRole([roles.SoftwareProductWrite]) | async" title="{{'common.remove' | translate}}" width="120">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="center">
                <button kendoGridRemoveCommand>
                    <svg class="icon"><use xlink:href="/assets/icons/symbol-defs.svg#icon-icons-delete"></use></svg>
                </button>    
            </div>
        </ng-template>
    </kendo-grid-command-column>
</kendo-grid>