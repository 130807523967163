/* eslint-disable class-methods-use-this */
/* eslint-disable no-param-reassign */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Software } from 'src/app/models/software.model';
import { from, Observable, ReplaySubject, throwError } from 'rxjs';
import { BsusResult } from 'src/app/models/bsus-result.model';
import { finalize, map, mergeMap } from 'rxjs/operators';
import { EditService } from '@progress/kendo-angular-grid';
import { Platform } from 'src/app/models/platform.model';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { BlockBlobClient } from '@azure/storage-blob'

@Injectable({
  providedIn: 'root'
})
export class DetailService implements EditService {
  constructor(private readonly http: HttpClient,
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService) {}

  public loading: boolean = false;

  private currentSoftware: string = "";
  private subject: ReplaySubject<Software[]> = new ReplaySubject<Software[]>(1);

  public getSoftwareDetails(software: string, renewCache: boolean = false): Observable<Software[]> {
    if(this.currentSoftware !== software || renewCache === true){
      this.currentSoftware = software;
      this.requestSoftware();
    }
    return this.subject.asObservable();
  }

  public searchSoftwareDetails(software: string, renewCache: boolean = false): Observable<Software[]> {
    if(this.currentSoftware !== software || renewCache === true){
      this.currentSoftware = software;
      this.searchSoftware();
    }
    return this.subject.asObservable();
  }

  private requestSoftware(): void {
    const path = `${environment.backend}software?softwareName=${this.currentSoftware}`;
    this.loadSoftware(path);
  }

  private searchSoftware(): void {
    const path = `${environment.backend}software/search?searchString=${this.currentSoftware}`;
    this.loadSoftware(path);
  }

  private loadSoftware(path: string): void
  {
    this.loading = true;
    this.http.get<BsusResult<Software>>(path).pipe(
      map((next) => next.result),
      map((next) =>
        next.map((x) => {
          x.date = x.date ? new Date(x.date) : null;
          x.releaseDate = x.releaseDate ? new Date(x.releaseDate) : null;
          x.revokeDate = x.revokeDate ? new Date(x.revokeDate) : null;
          x.created = x.created ? new Date(x.created) : null;
          x.modified = x.modified ? new Date(x.modified) : null;
          x.platformSearch = x.platforms.map(p => p.toLowerCase())
          return x;
        })
      )).subscribe({
        next: (next) => this.subject.next(next),
        error: (error) => {
          this.loading = false;
        },
        complete: () => this.loading = false
      });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public create(item: Software): void {
    if(!item.displayName){
      item.displayName = item.name + ' ' + item.version
    }
    
    if(!item.noAuthentication){
      //must not be null
      item.noAuthentication = false;
    }

    if(!item.released){
      //must not be null
      item.released = false;
    }

    const upload = (item.downloadUrl as unknown as File[])[0];

    const body = {
      name: item.name,
      version: item.version,
      displayName: item.displayName,
      downloadFileName: upload.name,
      releaseType: item.releaseType,
      platforms: item.platforms,
      description: item.description,
      noAuthentication: item.noAuthentication,
      packageType: item.packageType,
      countries: item.countries,
      languages: item.languages,
      release: item.released,
    }
    const message = item.displayName + this.translateService.instant('common.toastMessages.addSuccess')

    this.loading = true;

    this.http.post<BsusResult<Software>>(`${environment.backend}software`, body)
    .pipe(mergeMap( (next) => {
        const blobClient =  new BlockBlobClient(next.result[0].downloadUrl)
        return from(blobClient.uploadData(upload));
      }), 
      finalize(() => this.requestSoftware()))
      .subscribe({
        complete: () => this.toastService.ShowSuccess((message), false)
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public update(item: Software): void {
    this.loading = true;
    const body = {
      guid: item.guid,
      noAuthentication: item.noAuthentication,
      description: item.description,
      displayName: item.displayName,
      released: item.released,
      releaseDate: item.releaseDate,
      revokeDate: item.revokeDate,
      packageType: item.packageType,
      warningId: item.warningId,
      countries: item.countries,
      languages: item.languages
    };
    const message = item.displayName + this.translateService.instant('common.toastMessages.editSuccess');

    this.http.patch(`${environment.backend}software`, body)
    .pipe(finalize(() => this.requestSoftware()),).subscribe({
      complete: () => this.toastService.ShowSuccess((message), false)
    })
  }

  public remove(item: Software): void {
    this.loading = true;
    const message = item.displayName + this.translateService.instant('common.toastMessages.removeSuccess');

    this.http
      .delete(`${environment.backend}software?id=${item.guid}`)
      .pipe(finalize(() => this.requestSoftware())).subscribe({
        complete: () => this.toastService.ShowSuccess(message, false)
      });
  }

  public assignValues(target: Software, source: Software): void {
    Object.assign(target, source);
  }

  public getPlatformsForSoftware(software: Software): Observable<Platform[]> {
    const path = `${environment.backend}platform/forSoftware?softwareId=${software.guid}`;
    return this.http
      .get<BsusResult<Platform>>(path)
      .pipe(map((next) => next.result));
  }

  public setPlatformsForSoftware(
    software: Software,
    platforms: string[]
  ): void {
    const path = `${environment.backend}platform/forSoftware?softwareId=${software.guid}`;
    this.http.patch(path, platforms).subscribe();
  }
}
