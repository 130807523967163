<div class="autocomplete">
    <svg class="icon global-search" (click)="clickSearch($event)"><use xlink:href="/assets/icons/symbol-defs.svg#icon-icons-search"></use></svg>

    <kendo-autocomplete
        style="width:100%"
        [data]="view"
        [suggest]="true"
        [valueField]="'value'"
        [filterable]="true"
        [placeholder]="'common.search' | translate"
        (open)="open($event)"
        (filterChange)="filterChanged($event)"
        (focus)="focusSearch($event)"
        (blur)="blurSearch($event)"
        (keypress)="keyPressed($event)"
        (valueChange)="valueChanged($event)"
    >
    </kendo-autocomplete>

    <div class="content-overlay" *ngIf="showOverlay"></div>
</div>
