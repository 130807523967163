import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler, ElementRef } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { NotificationModule, NOTIFICATION_CONTAINER } from '@progress/kendo-angular-notification';
import { IntlModule } from '@progress/kendo-angular-intl';
import { MessageService } from '@progress/kendo-angular-l10n';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import { LayoutModule } from '@progress/kendo-angular-layout';
import '@progress/kendo-angular-intl/locales/de/all';
import { PopupModule } from '@progress/kendo-angular-popup';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { LabelModule } from '@progress/kendo-angular-label';
import { FileSelectModule, UploadModule } from '@progress/kendo-angular-upload';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import {
  TranslateModule,
  TranslateLoader,
  MissingTranslationHandler
} from '@ngx-translate/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OverviewComponent } from './routes/overview/overview.component';
import { httpInterceptorProviders } from './shared/interceptors/http.interceptors';
import { LocaleService } from './shared/translation/locale.service';
import { GlobalErrorHandler } from './shared/errorHandling/global-error-handler.service';
import { StaticTranslateLoader } from './shared/translation/static-translate.loader';
import { DefaultMissingTranslationHandler } from './shared/translation/default-missing-translation.handler';
import { KendoTranslationService } from './shared/translation/kendo-translation.service';
import { DetailComponent } from './routes/overview/detail/detail.component';
import { SettingsComponent } from './routes/settings/settings.component';
import { PlatformsComponent } from './routes/platforms/platforms.component';
import { SoftwareProductsComponent } from './routes/software-products/software-products.component';
import { SoftwareProductsDetailsComponent } from './routes/software-products/software-products-details/software-products-details.component';
import { ReleaseNotesComponent } from './routes/overview/release-notes/release-notes.component';
import { HeaderComponent } from './shared/header/header.component';
import { LanguageSwitchComponent } from './shared/header/language-switch/language-switch.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { SearchbarComponent } from './shared/header/searchbar/searchbar/searchbar.component';
import { FilterComponent } from './shared/filter/filter.component';

@NgModule({
  declarations: [
    AppComponent,
    OverviewComponent,
    DetailComponent,
    SettingsComponent,
    PlatformsComponent,
    SoftwareProductsComponent,
    SoftwareProductsDetailsComponent,
    ReleaseNotesComponent,
    HeaderComponent,
    LanguageSwitchComponent,
    BreadcrumbComponent,
    SearchbarComponent,
    FilterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ButtonsModule,
    DropDownsModule,
    GridModule,
    LayoutModule,
    NotificationModule,
    IntlModule,
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useClass: StaticTranslateLoader },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: DefaultMissingTranslationHandler
      }
    }),
    PopupModule,
    InputsModule,
    DialogsModule,
    LabelModule,
    UploadModule,
    FileSelectModule,
    NavigationModule,
    DateInputsModule
  ],
  providers: [
    httpInterceptorProviders,
    LocaleService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    {
      provide: MessageService,
      useClass: KendoTranslationService
    },
    {
      provide: NOTIFICATION_CONTAINER,
      useFactory: () => {
         //return the container ElementRef, where the notification will be injected
         return { nativeElement: document.body } as ElementRef;
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
