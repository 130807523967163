import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { locales } from 'src/locale/locales';
import { LocaleService } from '../../translation/locale.service';

@Component({
  selector: 'app-language-switch',
  templateUrl: './language-switch.component.html',
  styleUrls: ['./language-switch.component.scss']
})
export class LanguageSwitchComponent implements OnInit {
  public supportedLocales = locales;

  public selectedLocale: string;

  constructor(
    private readonly localeService: LocaleService,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit() {
    this.selectedLocale = this.localeService.getUserLocale();
  }

  public changeLocale(localeKey: string) {
    this.selectedLocale = localeKey;
    this.localeService.setUserLocale(this.selectedLocale);
    this.translateService.use(this.localeService.getUserLanguage());
  }
}
