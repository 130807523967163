export const locales = [
  {
    name: 'Deutsch',
    key: 'de-DE'
  },
  {
    name: 'English',
    key: 'en-US'
  }
];
