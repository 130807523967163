import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserService } from '../user/user.service';
import { Roles } from 'src/app/models/roles.enum';
import { SoftwareProductsComponent } from 'src/app/routes/software-products/software-products.component';
import { PlatformsComponent } from 'src/app/routes/platforms/platforms.component';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor(
    private readonly userSerice: UserService,
    private router: Router
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
      let roles: Roles[]; 
    
      if(route.component === SoftwareProductsComponent)
      {
        roles = [Roles.SoftwareProductRead, Roles.SoftwareProductWrite];  
      }
      else if(route.component === PlatformsComponent)
      {
        roles = [Roles.PlatformRead, Roles.PlatformWrite];  
      }
      else
      {
        return of(false);
      }
      
      return this.userSerice.hasAnyRole(roles);
  }
}
