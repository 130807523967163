import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  AddEvent,
  EditEvent,
  GridComponent,
  SaveEvent
} from '@progress/kendo-angular-grid';
import { Subject } from 'rxjs';
import { Platform } from 'src/app/models/platform.model';
import { UserService } from 'src/app/shared/user/user.service';
import { FilterService } from 'src/app/shared/filter/filter.service';
import { PlatformsService } from './platforms.service';
import { GridService } from 'src/app/shared/grid/grid.service';
import { Roles } from 'src/app/models/roles.enum';

@Component({
  selector: 'app-platforms',
  templateUrl: './platforms.component.html',
  styleUrls: ['./platforms.component.scss'],
  providers: [FilterService, GridService]
})
export class PlatformsComponent implements OnInit {
  public showEditPopup: boolean = false;

  public animate: {
    type: 'zoom';
    duration: 200;
  };

  @ViewChild('grid') grid: GridComponent;

  public editedRowIndex: number;

  public formGroup: FormGroup;

  public dataItem: Platform;

  public isNew: boolean;

  public itemToRemove: Platform;

  public removeConfirmationSubject: Subject<boolean> = new Subject<boolean>();

  public roles = Roles;

  constructor(
    public readonly platformService: PlatformsService,
    private readonly formBuilder: FormBuilder,
    public readonly userService: UserService,
    public readonly filterService: FilterService,
    public readonly gridService: GridService
  ) {
    this.createFormGroup = this.createFormGroup.bind(this);
    this.removeConfirmation = this.removeConfirmation.bind(this);
  }

  public ngOnInit(): void {
    this.loadPlatformList();
    let fields = ['name', 'description', 'modifiedBy', 'createdBy'];
    this.filterService.setFields(fields);
    this.filterService.gridFilter.subscribe(next => {
      this.gridService.filterChanged(next);
    });
    this.gridService.init(this.grid);
  }

  private loadPlatformList(): void {
    this.platformService.getPlatforms().subscribe({
      next: (next) => {
        this.processPlatformList(next);
      },
    });
  }

  private processPlatformList(platformList: Platform[]): void {
    this.ensureNewItemIsOnTop(platformList)   
    this.gridService.data = platformList;     
    this.gridService.processView()
  }

  private ensureNewItemIsOnTop(platformList : Platform[]){
    if(this.dataItem != null && this.isNew){
      if(this.gridService.state.sort == null){
        this.gridService.state.sort = [];
      }
      this.gridService.state.sort.unshift({
        field: 'isNew',
        dir: 'desc'
      });
      const index = platformList.findIndex(x => x.name == this.dataItem.name)
      if(index >= 0){
        platformList[index]['isNew'] = 1;
        this.isNew = false;
      }  
    }
  }

  public createFormGroup(args: any): FormGroup {
    const item = args.isNew ? {} : args.dataItem;
    const platform = item as Platform;

    this.formGroup = this.formBuilder.group({
      name: platform.name,
      description: platform.description,
      productHierarchyId: platform.productHierarchyId
    });

    return this.formGroup;
  }

  public addButtonClicked(): void {
    this.isNew = true;
    this.editedRowIndex = undefined;
    this.dataItem = {} as Platform;

    const event: AddEvent = {
      dataItem: this.dataItem,
      isNew: this.isNew,
      rowIndex: this.editedRowIndex,
      sender: this.grid
    };

    this.grid.add.emit(event);
    this.showEditPopup = true;
  }

  public showPopup() {
    this.showEditPopup = true;
  }

  public savePopup() {
    if(!this.formGroup.valid){
      this.formGroup.markAllAsTouched();
      return;
    };

    const event: SaveEvent = {
      formGroup: this.formGroup,
      rowIndex: this.editedRowIndex,
      isNew: this.isNew,
      dataItem: this.dataItem,
      sender: this.grid
    };

    this.grid.save.emit(event);
    this.closePopup();
  }

  public closePopup() {
    if (this.isNew) {
      this.grid.closeRow();
    } else {
      this.grid.closeRow(this.editedRowIndex);
    }
    this.showEditPopup = false;
  }

  public removeConfirmation(dataItem: Platform): Subject<boolean> {
    this.itemToRemove = dataItem;
    return this.removeConfirmationSubject;
  }

  public confirmRemove(shouldRemove: boolean): void {
    this.removeConfirmationSubject.next(shouldRemove);
    this.itemToRemove = null;
  }

  public editHandler(event: EditEvent) {
    this.dataItem = event.dataItem;
    this.isNew = event.isNew;
    this.editedRowIndex = event.rowIndex;
    this.showEditPopup = true;
  }
}
