export const kendo = {
  calendar: {
    today: 'Today'
  },
  dateinput: {
    increment: 'Increase value',
    decrement: 'Decrease value'
  },
  datepicker: {
    today: 'Today',
    toggle: 'Toggle calendar'
  },
  datetimepicker: {
    dateTab: 'Date',
    dateTabLabel: 'Date tab',
    timeTab: 'Time',
    timeTabLabel: 'Time tab',
    toggle: 'Toggle popup',
    accept: 'Set',
    acceptLabel: 'Set',
    cancel: 'Cancel',
    cancelLabel: 'Cancel',
    now: 'NOW',
    nowLabel: 'Select now',
    today: 'TODAY'
  },
  dialog: {
    closeTitle: 'Close'
  },
  autocomplete: {
    noDataText: 'No data found',
    clearTitle: 'clear'
  },
  combobox: {
    noDataText: 'No data found',
    clearTitle: 'clear'
  },
  dropdownlist: {
    noDataText: 'No data found',
    clearTitle: 'clear'
  },
  multiselect: {
    noDataText: 'No data found',
    clearTitle: 'clear'
  },
  editor: {
    alignCenter: 'Center text',
    alignJustify: 'Justify',
    alignLeft: 'Align text left',
    alignRight: 'Align text right',
    backColor: 'Background color',
    bold: 'Bold',
    cleanFormatting: 'Clean formatting',
    createLink: 'Insert link',
    dialogApply: 'Apply',
    dialogCancel: 'Cancel',
    dialogInsert: 'Insert',
    dialogUpdate: 'Update',
    fileText: 'Text',
    fileTitle: 'Title',
    fileWebAddress: 'Web address',
    fontFamily: 'Select font family',
    fontSize: 'Select font size',
    foreColor: 'Color',
    format: 'Format',
    imageAltText: 'Alternate text',
    imageHeight: 'Height (px)',
    imageWebAddress: 'Web address',
    imageWidth: 'Width (px)',
    indent: 'Indent',
    insertFile: 'Insert file',
    insertImage: 'Insert image',
    insertOrderedList: 'Insert ordered list',
    insertUnorderedList: 'Insert unordered list',
    italic: 'Italic',
    linkOpenInNewWindow: 'Open link in new window',
    linkText: 'Text',
    linkTitle: 'Title',
    linkWebAddress: 'Web address',
    outdent: 'Outdent',
    redo: 'Redo',
    strikethrough: 'Strikethrough',
    subscript: 'Subscript',
    superscript: 'Superscript',
    underline: 'Underline',
    unlink: 'Remove Link',
    undo: 'Undo',
    viewSource: 'View source',
    insertTable: 'Insert Table',
    addColumnBefore: 'Add column before',
    addColumnAfter: 'Add column after',
    addRowBefore: 'Add row before',
    addRowAfter: 'Add row after',
    deleteColumn: 'Delete column',
    deleteRow: 'Delete row',
    deleteTable: 'Delete table'
  },
  grid: {
    groupPanelEmpty:
      'Drag a column header and drop it here to group by that column',
    noRecords: 'No records available.',
    pagerFirstPage: 'Go to the first page',
    pagerPreviousPage: 'Go to the previous page',
    pagerNextPage: 'Go to the next page',
    pagerLastPage: 'Go to the last page',
    pagerPage: 'Page',
    pagerOf: 'of',
    pagerItems: 'items',
    pagerItemsPerPage: 'items per page',
    filter: 'Filter',
    filterEqOperator: 'Is equal to',
    filterNotEqOperator: 'Is not equal to',
    filterIsNullOperator: 'Is null',
    filterIsNotNullOperator: 'Is not null',
    filterIsEmptyOperator: 'Is empty',
    filterIsNotEmptyOperator: 'Is not empty',
    filterStartsWithOperator: 'Starts with',
    filterContainsOperator: 'Contains',
    filterNotContainsOperator: 'Does not contain',
    filterEndsWithOperator: 'Ends with',
    filterGteOperator: 'Is greater than or equal to',
    filterGtOperator: 'Is greater than',
    filterLteOperator: 'Is less than or equal to',
    filterLtOperator: 'Is less than',
    filterIsTrue: 'is true',
    filterIsFalse: 'is false',
    filterBooleanAll: '(All)',
    filterAfterOrEqualOperator: 'Is after or equal to',
    filterAfterOperator: 'Is after',
    filterBeforeOperator: 'Is before',
    filterBeforeOrEqualOperator: 'Is before or equal to',
    filterFilterButton: 'Filter',
    filterClearButton: 'Clear',
    filterAndLogic: 'And',
    filterOrLogic: 'Or',
    filterDateToggle: 'Toggle calendar',
    filterDateToday: 'Today',
    filterNumericDecrement: 'Decrease value',
    filterNumericIncrement: 'Increase value',
    loading: 'Loading',
    sort: 'Sort',
    columnMenu: 'Column Menu',
    columns: 'Columns',
    lock: 'Lock',
    unlock: 'Unlock',
    sortAscending: 'Sort Ascending',
    sortDescending: 'Sort Descending',
    columnsApply: 'Apply',
    columnsReset: 'Reset',
    sortable: 'Sortable',
    sortedAscending: 'Sorted ascending',
    sortedDescending: 'Sorted descending',
    sortedDefault: 'Not sorted'
  },
  notification: {
    closeTitle: 'Close'
  },
  numerictextbox: {
    increment: 'Increase value',
    decrement: 'Decrease value'
  },
  recurrenceeditor: {
    dailyInterval: 'day(s)',
    dailyRepeatEvery: 'Repeat every',
    endAfter: 'After',
    endLabel: 'End',
    endNever: 'Never',
    endOccurrence: 'occurrence(s)',
    endOn: 'On',
    frequenciesDaily: 'Daily',
    frequenciesMonthly: 'Monthly',
    frequenciesNever: 'Never',
    frequenciesWeekly: 'Weekly',
    frequenciesYearly: 'Yearly',
    monthlyDay: 'Day',
    monthlyInterval: 'month(s)',
    monthlyRepeatEvery: 'Repeat every',
    monthlyRepeatOn: 'Repeat on',
    offsetPositionsFourth: 'Fourth',
    offsetPositionsLast: 'Last',
    offsetPositionsSecond: 'Second',
    offsetPositionsThird: 'Third',
    repeat: 'Repeat',
    weekdaysDay: 'Day',
    weekdaysWeekday: 'Weekday',
    weekdaysWeekendday: 'Weekend Day',
    weeklyInterval: 'week(s)',
    weeklyRepeatEvery: 'Repeat every',
    weeklyRepeatOn: 'Repeat on',
    yearlyInterval: 'year(s)',
    yearlyOf: 'of',
    yearlyRepeatEvery: 'Repeat every',
    yearlyRepeatOn: 'Repeat on'
  },
  scheduler: {
    agendaViewTitle: 'Agenda',
    allDay: 'all day',
    allEvents: 'All events',
    calendarToday: 'TODAY',
    cancel: 'Cancel',
    save: 'Save',
    editorEventTitle: 'Title',
    editorEventStart: 'Start',
    editorEventStartTimeZone: 'Start Time Zone',
    editorEventEnd: 'End',
    editorEventEndTimeZone: 'End Time Zone',
    dateHeader: 'Date',
    dayViewTitle: 'Day',
    deleteConfirmation: 'Are you sure you want to delete this event?',
    deleteDialogTitle: 'Delete Event',
    deleteOccurrence: 'Delete current occurrence',
    deleteRecurringConfirmation:
      'Do you want to delete only this event occurrence or the whole series?',
    deleteRecurringDialogTitle: 'Delete Recurring Item',
    deleteSeries: 'Delete the series',
    deleteTitle: 'Delete',
    destroy: 'Delete',
    editOccurrence: 'Edit current occurrence',
    editorEventAllDay: 'All Day Event',
    editorEventDescription: 'Description',
    editorEventSeparateTimeZones: 'End in different Time Zone',
    editorEventTimeZone: 'Specify Time Zone',
    editorTitle: 'Event',
    editRecurringConfirmation:
      'Do you want to edit only this event occurrence or the whole series?',
    editRecurringDialogTitle: 'Edit Recurring Item',
    editSeries: 'Edit the series',
    eventHeader: 'Event',
    monthViewTitle: 'Month',
    multiDayViewTitle: 'Multi-Day',
    nextTitle: 'Next',
    previousTitle: 'Previous',
    recurrenceEditorDailyInterval: 'day(s)',
    recurrenceEditorDailyRepeatEvery: 'Repeat every',
    recurrenceEditorEndAfter: 'After',
    recurrenceEditorEndLabel: 'End',
    recurrenceEditorEndNever: 'Never',
    recurrenceEditorEndOccurrence: 'occurrence(s)',
    recurrenceEditorEndOn: 'On',
    recurrenceEditorFrequenciesDaily: 'Daily',
    recurrenceEditorFrequenciesMonthly: 'Monthly',
    recurrenceEditorFrequenciesNever: 'Never',
    recurrenceEditorFrequenciesWeekly: 'Weekly',
    recurrenceEditorFrequenciesYearly: 'Yearly',
    recurrenceEditorMonthlyDay: 'Day',
    recurrenceEditorMonthlyInterval: 'month(s)',
    recurrenceEditorMonthlyRepeatEvery: 'Repeat every',
    recurrenceEditorMonthlyRepeatOn: 'Repeat on',
    recurrenceEditorOffsetPositionsFirst: 'First',
    recurrenceEditorOffsetPositionsFourth: 'Fourth',
    recurrenceEditorOffsetPositionsLast: 'Last',
    recurrenceEditorOffsetPositionsSecond: 'Second',
    recurrenceEditorOffsetPositionsThird: 'Third',
    recurrenceEditorRepeat: 'Repeat',
    recurrenceEditorWeekdaysDay: 'Day',
    recurrenceEditorWeekdaysWeekday: 'Weekday',
    recurrenceEditorWeekdaysWeekendday: 'Weekend Day',
    recurrenceEditorWeeklyInterval: 'week(s)',
    recurrenceEditorWeeklyRepeatEvery: 'Repeat every',
    recurrenceEditorWeeklyRepeatOn: 'Repeat on',
    recurrenceEditorYearlyInterval: 'year(s)',
    recurrenceEditorYearlyOf: 'of',
    recurrenceEditorYearlyRepeatEvery: 'Repeat every',
    recurrenceEditorYearlyRepeatOn: 'Repeat on',
    showFullDay: 'Show full day',
    showWorkDay: 'Show business hours',
    timeHeader: 'Time',
    timelineMonthViewTitle: 'Timeline Month',
    timelineViewTitle: 'Timeline',
    timelineWeekViewTitle: 'Timeline Week',
    today: 'Today',
    weekViewTitle: 'Week',
    workWeekViewTitle: 'Work Week'
  },
  slider: {
    increment: 'Increase value',
    decrement: 'Decrease value',
    dragHandle: 'drag'
  },
  switch: {
    on: 'On',
    off: 'Off'
  },
  timepicker: {
    accept: 'Set',
    acceptLabel: 'Set',
    cancel: 'Cancel',
    cancelLabel: 'Cancel',
    now: 'Now',
    nowLabel: 'Now',
    toggle: 'Toggle time list'
  },
  tooltip: {
    closeTitle: 'Close'
  },
  treelist: {
    groupPanelEmpty:
      'Drag a column header and drop it here to group by that column',
    noRecords: 'No records available.',
    pagerFirstPage: 'Go to the first page',
    pagerPreviousPage: 'Go to the previous page',
    pagerNextPage: 'Go to the next page',
    pagerLastPage: 'Go to the last page',
    pagerPage: 'Page',
    pagerOf: 'of',
    pagerItemsTotal: 'items total',
    pagerItemsPerPage: 'items per page',
    filter: 'Filter',
    filterEqOperator: 'Is equal to',
    filterNotEqOperator: 'Is not equal to',
    filterIsNullOperator: 'Is null',
    filterIsNotNullOperator: 'Is not null',
    filterIsEmptyOperator: 'Is empty',
    filterIsNotEmptyOperator: 'Is not empty',
    filterStartsWithOperator: 'Starts with',
    filterContainsOperator: 'Contains',
    filterNotContainsOperator: 'Does not contain',
    filterEndsWithOperator: 'Ends with',
    filterGteOperator: 'Is greater than or equal to',
    filterGtOperator: 'Is greater than',
    filterLteOperator: 'Is less than or equal to',
    filterLtOperator: 'Is less than',
    filterIsTrue: 'is true',
    filterIsFalse: 'is false',
    filterBooleanAll: '(All)',
    filterAfterOrEqualOperator: 'Is after or equal to',
    filterAfterOperator: 'Is after',
    filterBeforeOperator: 'Is before',
    filterBeforeOrEqualOperator: 'Is before or equal to',
    filterFilterButton: 'Filter',
    filterClearButton: 'Clear',
    filterAndLogic: 'And',
    filterOrLogic: 'Or',
    loading: 'Loading',
    sort: 'Sort',
    columnMenu: 'Column Menu',
    columns: 'Columns',
    lock: 'Lock',
    unlock: 'Unlock',
    sortAscending: 'Sort Ascending',
    sortDescending: 'Sort Descending',
    columnsApply: 'Apply',
    columnsReset: 'Reset',
    sortable: 'Sortable',
    sortedAscending: 'Sorted ascending',
    sortedDescending: 'Sorted descending',
    sortedDefault: 'Not sorted'
  },
  upload: {
    cancel: 'Cancel',
    clearSelectedFiles: 'Clear',
    dropFilesHere: 'Drop files here to upload',
    externalDropFilesHere: 'Drag and drop files here to upload',
    filesBatchStatus: 'files',
    filesBatchStatusFailed: 'files failed to upload.',
    filesBatchStatusUploaded: 'files successfully uploaded.',
    fileStatusFailed: 'File failed to upload.',
    fileStatusUploaded: 'File successfully uploaded.',
    headerStatusPaused: 'Paused',
    headerStatusUploaded: 'Done',
    headerStatusUploading: 'Uploading...',
    invalidFileExtension: 'File type not allowed.',
    invalidMaxFileSize: 'File size too large.',
    invalidMinFileSize: 'File size too small.',
    remove: 'Remove',
    retry: 'Retry',
    select: 'Select files...',
    uploadSelectedFiles: 'Upload files'
  },
  window: {
    closeTitle: 'Close',
    restoreTitle: 'Restore',
    maximizeTitle: 'Maximize',
    minimizeTitle: 'Minimize'
  }
};
